import { useState, useEffect, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Row, Col, Button, Spinner } from "reactstrap";
import { H1, H3 } from "../components/elements";
import FriendList from "../components/FriendList";
import FriendNameModal from "../components/FriendNameModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { cleanFriendList, loadUser } from "../services/user";
import { withRouter } from "../legacy/react-router-old";
import { withUserContext } from "../UserContext";
import { MAX_NUMBER_FRIENDS_FREE } from "../constants";

const ChooseWhom = ({ currentUser }) => {
  const navigate = useNavigate();
  const [friendList, setFriendList] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [newFriend, setNewFriend] = useState({
    firstName: "",
    lastName: "",
    email: "",
    birthdayReadable: "",
    birthday: "",
    personSlug: "",
    relation: "",
  });
  const [subscriptionPlan, setSubscriptionPlan] = useState("free");
  const [isLoading, setIsLoading] = useState(true);
  const [isEditIconClick, setIsEditIconClick] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const user = currentUser;
      const friendList = await loadUser(user.id);
      const finalFriendList = await cleanFriendList(friendList);
      setFriendList(finalFriendList || {});
      setSubscriptionPlan(user.subscriptionPlan);
      setIsLoading(false);
    };
    fetchData();
  }, [currentUser]);

  const toggleModal = () => {
    setModalVisible(!modalVisible);
    setIsEditIconClick(false);
    setNewFriend({
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      personSlug: "",
      relation: "",
    });
  };

  const updateValue = (key, value) => {
    setNewFriend((prevNewFriend) => ({ ...prevNewFriend, [key]: value }));
  };

  const saveFriendList = (nextFriendPage) => {
    if (nextFriendPage) {
      navigate("/getting-to-know/" + nextFriendPage);
    } else {
      console.log(
        "Error: nextFriendPage URL is not valid. nextFriendPage= ",
        nextFriendPage,
      );
      navigate("/upcoming-birthdays");
    }
  };

  const sortFriendList = (friendListOriginal) => {
    var friendList = Object.keys(friendListOriginal).map((friendKey, index) => {
      return friendListOriginal[index];
    });
    friendList = friendList.sort((friendA, friendB) => {
      return friendA.birthday > friendB.birthday;
    });
    return friendList;
  };

  const handleClickNext = () => {
    const friendListSorted = sortFriendList(friendList);

    const selectedFriends = Object.entries(friendListSorted).filter(
      ([friendKey, friend]) => {
        return friend.selected;
      },
    );

    if (selectedFriends.length === 0) {
      alert("No friends selected, please select a friend to continue.");
    } else {
      const nextFriendPage = selectedFriends[0][1].user.personSlug;
      saveFriendList(nextFriendPage);
    }
  };

  const handleClickSkip = () => {};

  const handleSelectFriend = (friendKey) => {
    var newFriendList = { ...friendList };
    newFriendList[friendKey].selected = !newFriendList[friendKey].selected;
    setFriendList(newFriendList);
  };

  const handleEditIcon = (friendKey) => {
    const editFriend = friendList[friendKey];
    setNewFriend({
      id: editFriend.user.id,
      userFriendId: editFriend.id,
      firstName: editFriend.user.firstName,
      lastName: editFriend.user.lastName,
      email: editFriend.user.email,
      birthday: editFriend.user.birthday,
      personSlug: editFriend.user.personSlug,
      relation: editFriend.relation,
    });
    setModalVisible(true);
    setIsEditIconClick(true);
  };

  const handleSubmit = async (keepModalVisible) => {
    setNewFriend({
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      personSlug: "",
      relation: "",
    });
    if (
      Object.keys(friendList).length > 30 &&
      subscriptionPlan !== "premium" &&
      subscriptionPlan !== "ultimate" &&
      subscriptionPlan !== null
    ) {
      setModalVisible(false);
    } else {
      setModalVisible(keepModalVisible);
    }
    if (keepModalVisible) setModalVisible(true);
    else setModalVisible(false);
    setIsLoading(true);

    const friendListsObj = await loadUser(currentUser.id);
    const finalFriendList = await cleanFriendList(friendListsObj);
    setFriendList(finalFriendList || {});
    setIsLoading(false);
  };

  return (
    <Fragment>
      <FriendNameModal
        isOpen={modalVisible}
        onClose={toggleModal}
        onSubmit={(keepModalVisible) => handleSubmit(keepModalVisible)}
        newFriend={newFriend}
        updateValue={updateValue}
        id={currentUser.id}
        isEdit={isEditIconClick}
      />
      <H1 style={{ textAlign: "center", padding: 10 }}>Choose Whom</H1>
      <H3>Which friends would you like to send gifts to?</H3>
      <Row>
        {isLoading ? (
          <Col sm="12" md={{ size: 1, offset: 6 }}>
            {" "}
            <Spinner />
          </Col>
        ) : (
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            {Object.keys(friendList).length ? (
              <FriendList
                friendList={friendList}
                handleCheckBox={handleSelectFriend}
                handleEditIcon={handleEditIcon}
              />
            ) : (
              <div>No friends found.</div>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col sm="12" md="6">
          {Object.keys(friendList).length > MAX_NUMBER_FRIENDS_FREE &&
          subscriptionPlan !== "premium" &&
          subscriptionPlan !== "ultimate" ? (
            <div>
              <a href="https://www.generousgiver.life/plans-pricing">
                Upgrade Now
              </a>{" "}
              to Add More Friends
            </div>
          ) : (
            <Button
              onClick={toggleModal}
              style={{ backgroundColor: "white", borderWidth: 0 }}
              data-cy="add-friends-button"
            >
              <FontAwesomeIcon icon={faPlusCircle} size="3x" color="#33d3ea" />
              <div style={{ color: "black" }}>Add more friends</div>
            </Button>
          )}
        </Col>
        <Col sm="12" md="6">
          <Link to="/upcoming-birthdays">
            <Button
              color="secondary"
              onClick={handleClickSkip}
              style={{ marginRight: "5px" }}
            >
              Skip
            </Button>
          </Link>
          <Button color="primary" onClick={handleClickNext}>
            Next
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default withUserContext(withRouter(ChooseWhom));
