import React, { Fragment } from "react";
import SoftwareAbout from "../components/softwareAbout";
import FooterComponent from "../components/Footer";

const About = () => {
  return (
    <Fragment>
      <SoftwareAbout id="about" />
      <FooterComponent className="footerArea bookFooterArea" />
    </Fragment>
  );
};
export default About;
