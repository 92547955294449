import React from "react";
import { Div, P, Span, H3 } from "../elements";
import { Container, Row, Col } from "reactstrap";
import SectionTitle from "../SectionTitle";
import "./style.scss";

const testmonials = [
  {
    name: "Anonymous",
    subTitle: "",
    text: "It makes it easy. It’s not the money that makes it [gift buying] difficult, but it’s the time and hassle of going to the store.",
  },
  {
    name: "Joseph",
    subTitle: "",
    text: "Helps out when you’re busy and you forget about a birthday or anniversary. Go get it done real quick.",
  },
];

const TestmonialComponent = ({ className, id }) => {
  return (
    <Div className={className} id={id}>
      <Container>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <SectionTitle
              className="sectionTitle"
              title="What people are saying"
              text=""
            />
          </Col>
          {testmonials.map((testmonial, i) => (
            <Col key={i} lg={6}>
              <Div className="testWrap">
                <H3>{testmonial.name}</H3>
                <Span>{testmonial.subTitle}</Span>
                <P>{testmonial.text}</P>
              </Div>
            </Col>
          ))}
        </Row>
      </Container>
    </Div>
  );
};
export default TestmonialComponent;
