import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Div, H3, Ul, Li, Button, H4, H5 } from "../elements";
import { Link } from "react-router-dom";

import SectionTitle from "../SectionTitle";
import "./style.scss";

const pricingTables = [
  {
    title: "Starter",
    value: "Free",
    details: [
      "Up to 10 reminders per year",
      "Up to 5 gift recommendations per year",
    ],
    link: "/signup?=starter",
  },
  {
    title: "Share the love",
    value: "$5",
    details: [
      "Up to 50 reminders per year",
      "Up to 24 gift recommendations per year",
    ],
    className: "active",
    link: "/signup?=premium",
  },
  {
    title: "Life of the party",
    value: "$10",
    details: [
      "Unlimited reminders per year",
      "Up to 120 gift recommendations per year",
    ],
    link: "/signup?=ultimate",
  },
];

const PricingTable = ({ id, className, noGutters }) => {
  return (
    <Div
      className={
        className ? `yogaPricingTableArea ${className}` : "yogaPricingTableArea"
      }
      id={id}
    >
      <Container>
        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <SectionTitle
              className="sectionTitle yogaSectionTitle"
              title="Pricing"
              text="There's no one size fits all, so consider who you'd like to send gifts to given your budget and desire. Then decide where you'd like to start. Don't worry you can cancel at any time and always upgrade when you're ready."
            />
          </Col>
        </Row>
        <Row>
          {pricingTables.map((pricin, i) => (
            <Col key={i} lg={4} md={6} xs={12}>
              <Div
                className={
                  pricin.className
                    ? `yogaPricingWrapper ${pricin.className}`
                    : "yogaPricingWrapper"
                }
              >
                <H3>{pricin.title}</H3>
                <H4>{pricin.value}</H4>
                {pricin.value !== "Free" ? (
                  <H5>per month</H5>
                ) : (
                  <div style={{ height: 30 }} />
                )}
                <Ul>
                  {pricin.details.map((item) => (
                    <Li key={item}>{item}</Li>
                  ))}
                </Ul>
                <Link to={pricin.link}>
                  <Button>GET STARTED</Button>
                </Link>
              </Div>
            </Col>
          ))}
        </Row>
      </Container>
    </Div>
  );
};
export default PricingTable;
