import React, { Fragment } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

class EmailSignUp extends React.Component {
  render() {
    const { isOpen, toggleClose, onSubmit, email, updateValue } = this.props;
    return (
      <Fragment>
        <Modal isOpen={isOpen} toggle={toggleClose}>
          <ModalHeader toggle={toggleClose}>Get Started</ModalHeader>
          <ModalBody>
            <div style={{ padding: 10 }}>
              We're excited to help you connect with your friends.
            </div>
            <Form onSubmit={onSubmit}>
              <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input
                  type="email"
                  name="email"
                  id="email1234"
                  placeholder="susie@gmail.com"
                  value={email}
                  onChange={(event) => updateValue("email", event.target.value)}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleClose}>
              Cancel
            </Button>
            <Button color="primary" onClick={onSubmit}>
              Next
            </Button>
          </ModalFooter>
        </Modal>
      </Fragment>
    );
  }
}
export default EmailSignUp;
