import { Navigate } from "react-router-dom";
import { withUserContext } from "../UserContext";

const Dashboard = ({ currentUser }) => {
  const onBoardingComplete = currentUser?.onBoardingComplete;
  //console.log("currentUser: ", currentUser)
  //console.log("OnboardingComplete: ", onBoardingComplete)
  if (!onBoardingComplete) {
    return <Navigate to="/profile" />;
  } else {
    return <Navigate to="/upcoming-birthdays" />;
  }
};

export default withUserContext(Dashboard);
