/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2mcracmbrrhxzf642qfvuzebp4.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-gj7oykv36belrgqnhsfwkyc73e",
    "aws_cognito_identity_pool_id": "us-east-1:42484824-72ac-4899-9d18-0b3ec670bed1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5QXEacFfX",
    "aws_user_pools_web_client_id": "1gig4kpg0qj9c8idjp3dm2n621",
    "oauth": {
        "domain": "generousgiveree5b80e7-ee5b80e7-production.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:5173/,https://app-dev.generous-giver.com/,https://app-test.generous-giver.com/,https://app.generous-giver.com/",
        "redirectSignOut": "http://localhost:5173/,https://app-dev.generous-giver.com/,https://app-test.generous-giver.com/,https://app.generous-giver.com/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "FACEBOOK"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "generous-giver-20230822170713-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d9fijjg9xk0j.cloudfront.net"
};


export default awsmobile;
