/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      personSlug
      firstName
      lastName
      email
      phone
      addressStreet1
      addressStreet2
      addressCity
      addressState
      addressZip
      addressCountry
      birthday
      annualBudget
      createdAt
      updatedAt
      description
      friends {
        nextToken
        __typename
      }
      viewers
      daysBetweenReminders
      maxDaysBeforeEventToRemind
      sendEmailBirthdayReminders
      subscriptionPlan
      subscriptionStatus
      subscriptionStartedAt
      paymentGatewayId
      profileImageUrl
      surveysAboutUser {
        nextToken
        __typename
      }
      surveysTaken {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getUserFriend = /* GraphQL */ `
  query GetUserFriend($id: ID!) {
    getUserFriend(id: $id) {
      id
      primaryUserID
      user {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      relation
      selected
      lastGiftPurchasedDateTime
      generalGiftIdeas {
        description
        active
        createdAt
        createdByID
        __typename
      }
      giftRecommendations {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listUserFriends = /* GraphQL */ `
  query ListUserFriends(
    $filter: ModelUserFriendFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserFriends(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        primaryUserID
        relation
        selected
        lastGiftPurchasedDateTime
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getGiftRecommendation = /* GraphQL */ `
  query GetGiftRecommendation($id: ID!) {
    getGiftRecommendation(id: $id) {
      id
      userFriendID
      product {
        id
        externalId
        externalUrl
        externalDomain
        title
        description
        productImageUrl
        rating
        price
        currency
        createdAt
        updatedAt
        __typename
      }
      viewed
      purchasedOn
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listGiftRecommendations = /* GraphQL */ `
  query ListGiftRecommendations(
    $filter: ModelGiftRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftRecommendations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFriendID
        viewed
        purchasedOn
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getSurveyResponse = /* GraphQL */ `
  query GetSurveyResponse($id: ID!) {
    getSurveyResponse(id: $id) {
      id
      title
      description
      takenByUserID
      takenBy {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      aboutUserID
      aboutUser {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      answers {
        nextToken
        __typename
      }
      viewers
      aiGiftIdeaLastRunAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listSurveyResponses = /* GraphQL */ `
  query ListSurveyResponses(
    $filter: ModelSurveyResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        takenByUserID
        aboutUserID
        viewers
        aiGiftIdeaLastRunAt
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnswer = /* GraphQL */ `
  query GetAnswer($id: ID!) {
    getAnswer(id: $id) {
      id
      surveyID
      response
      giftIdea
      aiGiftIdea
      viewers
      createdAt
      updatedAt
      question {
        id
        secondPersonForm
        text
        thirdPersonForm
        placeholder
        active
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const listAnswers = /* GraphQL */ `
  query ListAnswers(
    $filter: ModelAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        surveyID
        response
        giftIdea
        aiGiftIdea
        viewers
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      id
      externalId
      externalUrl
      externalDomain
      title
      description
      productImageUrl
      rating
      price
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        externalId
        externalUrl
        externalDomain
        title
        description
        productImageUrl
        rating
        price
        currency
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      userFriendID
      qna {
        question
        answer
        __typename
      }
      message
      aiMessage
      scheduledToSendOn
      messageSentOn
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userFriendID
        message
        aiMessage
        scheduledToSendOn
        messageSentOn
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const userByPhone = /* GraphQL */ `
  query UserByPhone(
    $phone: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByPhone(
      phone: $phone
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      secondPersonForm
      text
      thirdPersonForm
      placeholder
      active
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        secondPersonForm
        text
        thirdPersonForm
        placeholder
        active
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
