import { Card, CardBody, Button, Col, CardImg } from "reactstrap";
import { faClose, faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NoImageFound from "../../images/NoImageFound.png";
import "./style.scss";

const GiftItemCard = ({
  id,
  title,
  imageUrl,
  cardText,
  cardLink,
  price,
  rating,
  handleDeleteProductIconClick,
}) => {
  //console.log(id);
  return (
    <Col md={4} sm={6} lg={3} xs={12} className="mb-4">
      <Card className="product-card" data-cy={`product-id-${id}`}>
        <CardBody className="product-body-img">
          <CardImg
            top
            width="100%"
            src={imageUrl || NoImageFound}
            alt="ProductImageCard"
            className="product-img"
          />
        </CardBody>
        <CardBody className="product-body product-info">
          <div className="text-rating">
            {/* Valid Options: ["2xs","xs","sm","lg","xl","2xl","1x","2x","3x","4x","5x","6x","7x","8x","9x","10x"].*/}
            {rating || "?"}
            &nbsp;
            <FontAwesomeIcon icon={faStar} size="lg" color="#edca4b" />
          </div>
          <div className="product-title">
            {title
              ? title.length > 28
                ? `${title.substring(0, 28)}... `
                : title
              : "Sample Image"}
          </div>
          <div className="product-desc text-muted">{cardText || ""}</div>
          <div style={{ textAlign: "left" }} className="text-price">
            ${price || "???"}
          </div>
          <a href={cardLink.url} target="_blank" rel="noopener noreferrer">
            <Button className="btn-buy-now">{cardLink.text}</Button>
          </a>
        </CardBody>
        <Button
          className="remove-product-icon"
          onClick={handleDeleteProductIconClick}
        >
          <FontAwesomeIcon icon={faClose} size="lg" color="#808080" />
        </Button>
      </Card>
    </Col>
  );
};

export default GiftItemCard;
