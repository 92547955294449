import { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import "./style.scss";
import { toast } from "react-toastify";
import { useParams } from "react-router";
import moment from "moment";
import { loadUser } from "../../services/user";
import { useNavigate } from "react-router-dom";
import { withUserContext } from "../../UserContext";
import CustomLoadingSpinner from "../../components/CustomLoadingSpinner";
import { getNextBirthday, convertListToObject } from "../../utils";
import {
  addHBDMessage,
  getHBDMessage,
  updateHBDMessage,
} from "../../services/message";

const CreateHBDMessage = ({ currentUser }) => {
  const navigate = useNavigate();
  const { personSlug } = useParams();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [hbdMessage, setHbdMessage] = useState("");
  const [validateHBDMessage, setValidateHBDMessage] = useState(false);
  const [questionAnswers, setQuestionAnswers] = useState({
    qualities: "",
    thankfulReason: "",
    joyfulExperience: "",
  });
  const [currentFriendUser, setCurrentFriendUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSavingMessage, setIsSavingMessage] = useState(false);
  const [upcomingBirthdayDate, setUpcomingBirthdayDate] = useState();
  const [isHBDMessageSchedule, setIsHBDMessSchedule] = useState(null);

  useEffect(async () => {
    if (personSlug) {
      const loggedInUserData = await loadUser(currentUser.id);
      const friendObj = loggedInUserData.friends.items.find((friend) => {
        return friend.user.personSlug === personSlug;
      });
      setCurrentFriendUser(friendObj);
      const upcomingBirthdayDate = getNextBirthday(
        new Date(),
        friendObj.user.birthday,
      );
      setUpcomingBirthdayDate(upcomingBirthdayDate);
      const res = await getHBDMessage(friendObj.id);
      if (res) {
        const timeDifference = Math.abs(upcomingBirthdayDate - new Date());
        const hoursDifference = timeDifference / (1000 * 60 * 60);
        if (!res.messageSentOn && hoursDifference > 2) {
          setIsHBDMessSchedule(res);
          setQuestionAnswers({
            qualities: res.qna[0].answer,
            thankfulReason: res.qna[1].answer,
            joyfulExperience: res.qna[2].answer,
          });
          setHbdMessage(res.message);
        }
      }
      setIsLoading(false);
    }
  }, [personSlug]);

  const createHBDMessageQuestionList = [
    {
      id: 1,
      title:
        "List 2 to 4 character qualities you appreciate or admire about this person.",
      name: "qualities",
      placeholder: 'E.g., Kind, Caring, Honest"',
      type: "textarea",
    },
    {
      id: 2,
      title: "Why are you thankful for their friendship?",
      name: "thankfulReason",
      placeholder: "E.g., Supportive, Understanding, Trustworthy",
      type: "textarea",
    },
    {
      id: 3,
      title: "What is one shared experience which brought you great joy?",
      name: "joyfulExperience",
      placeholder: "E.g., Traveling together, Celebrating milestones",
      type: "textarea",
    },
  ];
  const questionListObject = convertListToObject(createHBDMessageQuestionList);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!hbdMessage) setValidateHBDMessage(true);
    else {
      setIsConfirmModalOpen(true);
      setValidateHBDMessage(false);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setQuestionAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: value,
    }));
  };

  const handleScheduleMessgae = async (toastFlag = "") => {
    if (toastFlag !== "saveForLater") setIsSavingMessage(true);

    const qna = [];
    // qna: [
    //   {
    //     question: "",
    //     answer: "",
    //   },
    //   {
    //     question: "",
    //     answer: "",
    //   },
    //   {
    //     question: "",
    //     answer: "",
    //   },
    // ],
    for (const key in questionAnswers) {
      if (questionAnswers.hasOwnProperty(key)) {
        qna.push({
          question: questionListObject[key].title,
          answer: questionAnswers[key].trim(),
        });
      }
    }

    let messageDetail = {
      userFriendID: currentFriendUser.id,
      qna: qna,
      message: hbdMessage.trim(),
      scheduledToSendOn: upcomingBirthdayDate,
    };

    if (isHBDMessageSchedule) {
      messageDetail = { ...messageDetail, id: isHBDMessageSchedule.id };
    }

    isHBDMessageSchedule
      ? await updateHBDMessage(messageDetail)
          .then((res) => {
            toast.success("Message scheduled updated!");
            resetState();
            navigate("/");
          })
          .catch((err) => {
            console.log(err);
          })
      : await addHBDMessage(messageDetail)
          .then((res) => {
            toast.success(
              `${
                toastFlag === "save"
                  ? "Message scheduled!"
                  : "Message saved for later!"
              }`,
            );
            resetState();
            navigate("/");
          })
          .catch((err) => {
            console.log(err);
          });
  };

  const resetState = () => {
    setIsLoading(false);
    setIsSavingMessage(false);
    setIsConfirmModalOpen(false);
    setHbdMessage("");
    setQuestionAnswers({
      qualities: "",
      thankfulReason: "",
      joyfulExperience: "",
    });
  };

  return (
    <Container>
      {isLoading ? (
        <CustomLoadingSpinner />
      ) : (
        <Row className="create-hbd-message-wrapper justify-content-center">
          <Col md={6} xs={12}>
            <h2 className="mb-5">Craft Happy Birthday Message</h2>
            <Form onSubmit={handleSubmit}>
              {createHBDMessageQuestionList.map((question, index) => {
                return (
                  <FormGroup key={question.id}>
                    <Label for={question.name}>
                      {index + 1}.&nbsp;
                      {question.title}
                    </Label>
                    <Input
                      type={question.type}
                      name={question.name}
                      id={question.name}
                      placeholder={question.placeholder}
                      rows="3"
                      value={questionAnswers[question.name]}
                      onChange={handleChange}
                    />
                  </FormGroup>
                );
              })}
              <FormGroup>
                <Label for="hbdMessage">Write Happy Birthday Message.</Label>
                <Input
                  type="textarea"
                  name="hbdMessage"
                  id="hbdMessage"
                  rows="6"
                  placeholder="Write your happy birthday message here..."
                  value={hbdMessage}
                  onChange={(e) => setHbdMessage(e.target.value)}
                  invalid={validateHBDMessage}
                />
                {validateHBDMessage && (
                  <div className="text-danger error-text">
                    Message is required.
                  </div>
                )}
              </FormGroup>
              <div className="birthday-text">
                {!isLoading &&
                  `Birthday on ${moment(upcomingBirthdayDate).format(
                    "MMMM, Do YYYY",
                  )}.`}
              </div>
              <div className="text-right-wrapper mt-4">
                <Button
                  color="primary"
                  onClick={() => navigate("/")}
                  className="m-1"
                >
                  Back
                </Button>
                <Button color="primary" type="submit">
                  {isHBDMessageSchedule ? "Update Message" : "Schedule Message"}
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      )}
      {isConfirmModalOpen && (
        <Modal
          isOpen={isConfirmModalOpen}
          onClosed={resetState}
          toggle={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
        >
          <ModalHeader
            toggle={() => setIsConfirmModalOpen(!isConfirmModalOpen)}
          >
            Please confirm you’d like to send this message to your friend on{" "}
            {moment(upcomingBirthdayDate).format("MM/DD/YYYY")}
          </ModalHeader>
          <ModalBody>{hbdMessage}</ModalBody>
          <ModalFooter>
            <div
              className="text-right-wrapper"
              style={{ display: "flex", gap: "5px", alignItems: "center" }}
            >
              {!isHBDMessageSchedule && (
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => handleScheduleMessgae("saveForLater")}
                  className="m-1"
                >
                  Save For Later
                </Button>
              )}
              {isSavingMessage ? (
                <Spinner />
              ) : (
                <Button
                  color="primary"
                  type="submit"
                  onClick={() => handleScheduleMessgae("save")}
                >
                  Schedule Message
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      )}
    </Container>
  );
};

export default withUserContext(CreateHBDMessage);
