import moment from "moment";
import uuidv4 from "uuid/v4";
import { API, graphqlOperation, Auth } from "aws-amplify";

import { listUsers, userByEmail } from "../graphql/queries";
import { getUser } from "../graphql/queries_manual";

import {
  createUser,
  updateUser,
  createUserFriend,
  updateUserFriend,
  deleteUserFriend,
} from "../graphql/mutations";
import {
  nameToSlug,
  removeBlankAttributes,
  addBirthdayReadable,
  sortObjectArray,
  sortFriendsList,
} from "../utils";

const missingRequiredAttributes = (userData) => {
  // Email is not required yet.
  // if (!userData.email) {
  //   alert("User Not Saved. Email is required.");
  //   return true;
  // }
  if (!userData.firstName) {
    alert("User Not Saved. First name is required.");
    return true;
  }
  return false;
};

// TODO: Make sure the personSlug is globally unique
// TODO: Check if User Email already exists in database and return found user instead of creating a new one if so.
export const createNewUserInDb = async (user) => {
  //console.log({ user }, "inside createNewUserInDb func ");
  const userDetail = {
    input: user,
    // {
    // id: user.id,
    // firstName: user.firstName,
    // lastName: user.lastName,
    // email: user.email,
    // birthday: new Date(user.birthday),
    // personSlug: user.personSlug
    //}
  };

  try {
    const newUser = await API.graphql(graphqlOperation(createUser, userDetail));
    const userInfo = newUser.data.createUser;
    //console.log({ userInfo });
    return userInfo;
  } catch (err) {
    console.log(err);
    console.log(userDetail);
    //alert('Error: Failed to save. Please try again.');
    return false;
  }
};

export const cleanUserData = (user) => {
  var userData = Object.assign({}, user);
  // New User
  if (missingRequiredAttributes(userData)) {
    throw new Error("Error: User Not saved. Missing Required Attributes.");
  }

  //userData.personSlug = nameToSlug(userData.firstName + ' ' + userData.lastName)
  // Remove Blank Variables first because DynamoDB throws an error if blank variables are included
  userData = removeBlankAttributes(userData);
  delete userData.birthdayReadable; // Remove birthdayReadable (not saved to DynamoDB)
  return userData;
};

//Refactor this so the user object is not mutated
export const addNewUserIDAndSlug = (user) => {
  if (!user.id) {
    user.id = uuidv4();
  }
  user.personSlug = nameToSlug(user.firstName, user.lastName, user.id);
  //console.log({ user }, "after add slug");
  return user;
};

export const cleanAndSaveNewUser = async (user) => {
  //console.log("CurrentUser: cleanAndSaveNewUser ", user);
  user = addNewUserIDAndSlug(user);
  //console.log({ user }, "after add slug and id ");
  const userData = cleanUserData(user);
  // console.log("Cleaned User: cleanAndSaveNewUser", userData);
  const userInfo = await createNewUserInDb(userData);
  //console.log({ userInfo });
  return userInfo;
};

export const updateUserInDb = async (user) => {
  //console.log({ user });
  if (!user.id)
    throw new Error("Error: User Not saved. User Id is required.", user);

  // const userData = cleanUserData(user);
  // console.log("Cleaned User:", userData);

  const updateUserInput = {
    input: user,
  };

  try {
    const updatedUser = await API.graphql(
      graphqlOperation(updateUser, updateUserInput),
    );
    const userInfo = updatedUser.data.updateUser;
    return userInfo;
  } catch (err) {
    console.log(err);
    console.log(updateUserInput);
    //alert('Error: Failed to save. Please try again.');
    return false;
  }
};

export const updateBudgetOfUser = async (userDetail) => {
  try {
    const user = await API.graphql(graphqlOperation(updateUser, userDetail));
    return user.data.updateUser || null;
  } catch (err) {
    console.log(err);
  }
};

// Note this should be changed to LoadCurrentCognitoUser
export const loadCognitoUser = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const cognitoUserDataKey = user.userDataKey;
    return { cognitoUserInfo: user, cognitoUserDataKey };
  } catch (err) {
    console.log(err);
    return { cognitoUserInfo: null, cognitoUserDataKey: null };
  }
};

function convertStringToDates(friendList) {
  if (!friendList) {
    console.log(
      "Warning:: user.js::convertStringToDates:: friendList was empty.",
    );
    return [];
  }
  // Convert the lastGiftPurchasedDateTime and birthday from strings to dates
  friendList = friendList.map((currentFriend) => {
    if (currentFriend.lastGiftPurchasedDateTime)
      currentFriend.lastGiftPurchasedDateTime = new Date(
        currentFriend.lastGiftPurchasedDateTime,
      );
    if (currentFriend.user.birthday)
      currentFriend.user.birthday = new Date(currentFriend.user?.birthday);
    return currentFriend;
  });
  return friendList;
}

export const loadUser = async (cognitoUserDataKey) => {
  let res = await API.graphql(
    graphqlOperation(getUser, { id: cognitoUserDataKey }),
  );
  //if(res.errors) console.log("LoadUser Errors:: ", res.errors)
  let userData = res.data.getUser;
  //console.log("loadUser:: user=", { userData });
  // console.log({ cognitoUserDataKey }, { email }, { personSlug });
  // if (!userData) {
  //   const createUserInput = {
  //     id: cognitoUserDataKey,
  //     personSlug: personSlug,
  //     email: email,
  //     firstName: email.split("@")[0],
  //   };
  //   console.log({ createUserInput });
  //   const res = await createNewUserInDb(createUserInput);
  //   console.log({ res }, "after create dynomodb user response: ");
  //   // Convert the lastGiftPurchasedDateTime and birthday from strings to dates
  //   res.friends.items = convertStringToDates(userData.friends.items);
  //   return res;
  // }
  userData.friends.items = convertStringToDates(userData.friends.items);
  return userData;
};

export const cleanFriendList = (userData) => {
  // userData should be the user object model.

  //console.log(userData)
  if (!userData || !userData.friends) {
    // No friends found for user.
    return [];
  }
  var friendList = addBirthdayReadable(userData.friends.items) || [];
  //console.log("FriendList: ", friendList)
  friendList = convertStringToDates(friendList);

  friendList = sortFriendsList(new Date(), friendList);
  return friendList;
};

export const loadUserByEmail = async (email) => {
  try {
    let res = await API.graphql(graphqlOperation(userByEmail, { email }));
    //console.log(res)
    const itemsFound = res.data.userByEmail.items;
    if (itemsFound.length > 1) {
      throw Error("Multiple matching emails found.");
    }
    return itemsFound[0] || null;
  } catch (err) {
    console.log(err);
  }
};

export const addFriendAttributes = (friend) => {
  friend = addNewUserIDAndSlug(friend);
  //friend.id = uuidv4()
  //friend.personSlug = nameToSlug( friend.firstName, friend.lastName, friend.id )
  friend.selected = true;
  if (!friend.birthday) {
    friend.birthday = moment(friend.birthdayReadable, "MM-DD-YYYY").toDate();
  }
  var friend_clean = removeBlankAttributes(friend);
  return friend_clean;
};

function filterUserAttributes(inputObject) {
  // return an object which only has the attributes available in the user object.
  const userAttributes = [
    "id",
    "personSlug",
    "firstName",
    "lastName",
    "email",
    "phone",
    "addressStreet1",
    "addressStreet2",
    "addressCity",
    "addressState",
    "addressZip",
    "addressCountry",
    "birthday",
    "annualBudget",
    "createdAt",
    "updatedAt",
    "description",
    "friends",
    "daysBetweenReminders",
    "maxDaysBeforeEventToRemind",
    "sendEmailBirthdayReminders",
    "subscriptionPlan",
    "subscriptionStatus",
    "subscriptionStartedAt",
    "paymentGatewayId",
  ];

  const filteredObject = {};

  for (const key of userAttributes) {
    if (inputObject.hasOwnProperty(key)) {
      if (key === "birthday") {
        filteredObject[key] = new Date(inputObject["birthday"]);
      } else {
        filteredObject[key] = inputObject[key];
      }
    }
  }

  return filteredObject;
}

//TODO: Check if the relationship already exists, and if it does then don't recreate it.
export const createUserFriendConnection = async (
  userId,
  userFriendId,
  friendAttributes,
) => {
  // create a new userFriend object to save the data to the database.
  //primaryUserID, userFriendUserId, relation: "colleague"
  const userFriendInput = {
    primaryUserID: userId,
    userFriendUserId: userFriendId,
    relation: friendAttributes.relation || "friend",
    selected: friendAttributes.selected || false,
  };
  //console.log(userFriendInput)
  let res = await API.graphql(
    graphqlOperation(createUserFriend, { input: userFriendInput }),
  );

  return res.data.createUserFriend;
};

export const updateUserFriendConnection = async (
  userFriendId,
  userFriendAttributes,
  userId,
) => {
  const updateUserFriendInput = {
    id: userFriendId,
    primaryUserID: userId,
    userFriendUserId: userFriendAttributes.id,
    relation: userFriendAttributes?.relation,
    selected: userFriendAttributes?.selected,
    lastGiftPurchasedDateTime: userFriendAttributes?.lastGiftPurchasedDateTime,
  };
  let res = await API.graphql(
    graphqlOperation(updateUserFriend, { input: updateUserFriendInput }),
  );
  return res.data.updateUserFriend;
};

export const saveFriendToUserProfile = async (userId, friend) => {
  // First, see if the friend's email or phone already exists in the database, if not create a new user.
  // Second, attach the new user to the list of friends for the primary user (userId)
  var userFriendId = null;
  var friendUser = await loadUserByEmail(friend);
  if (!friendUser) {
    // The friend object has more attributes than is contained within the user object, so we need to remove the attributes not found in the User model.
    var userAttributes = filterUserAttributes(friend);

    try {
      friendUser = await createNewUserInDb(userAttributes);
    } catch (error) {
      console.log(error);
      return;
    }

    if (!friendUser) {
      throw new Error("Failed to create new User for Friend.", userAttributes);
    }
    userFriendId = friendUser.id;
  } else {
    userFriendId = friendUser.id;
  }

  //console.assert(!('id' in friend), "Missing an ID for friend.")
  const userFriendInfo = await createUserFriendConnection(
    userId,
    userFriendId,
    friend,
  );
  return userFriendInfo;
};

export const editFriendToUserProfile = async (userId, friend) => {
  let userFriendId = null;
  let friendUser = await loadUser(friend.id);
  if (friendUser) {
    var userAttributes = filterUserAttributes(friend);
    try {
      friendUser = await updateUserInDb(userAttributes);
    } catch (error) {
      console.log(error);
      return;
    }

    if (!friendUser) {
      throw new Error("Failed to update User for Friend.", userAttributes);
    }
    userFriendId = friend.userFriendId;
  } else {
    userFriendId = friend.userFriendId;
  }
  const userFriendInfo = await updateUserFriendConnection(
    userFriendId,
    friend,
    userId,
  );
  return userFriendInfo;
};

// TODO: Modify this to use the new methodology? Do we need to save the entire friend list anymore since it is saved with each user?

export const saveFriendListToUser = async (userId, friendListOriginal) => {
  var friendList = [];
  if (Object.keys(friendListOriginal).length > 0) {
    friendList = Object.keys(friendListOriginal).map((friendKey) => {
      const friend = friendListOriginal[friendKey];
      delete friend.birthdayReadable;
      return friend;
    });
  } else {
    friendList = friendListOriginal.map((friend) => {
      delete friend.birthdayReadable;
      return friend;
    });
  }

  const userDetail = {
    input: {
      // TODO: Modify this to match the format saved in the state. Look up how to
      friends: friendList,
      id: userId,
    },
  };
  try {
    const user = await API.graphql(graphqlOperation(updateUser, userDetail));
    const userInfo = user.data.updateUser;
    return userInfo || null;
  } catch (err) {
    console.log(err);
    alert("Error: Failed to save. Please try again.");
  }
};

const loadUsers = async () => {
  try {
    const allUsers = await API.graphql(graphqlOperation(listUsers));
    return allUsers.data.listUsers.items;
  } catch (err) {
    console.log(err);
  }
};

export const deleteUserFriendConnection = async (userFriendId) => {
  try {
    let res = await API.graphql(
      graphqlOperation(deleteUserFriend, { input: { id: userFriendId } }),
    );
    return res.data.createUserFriend;
  } catch (err) {
    console.log(err);
  }
};
