import React from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";

const TextAreaInput = ({ inputDetails, updateValue, inputValue, ...props }) => {
  //console.log("TextAreaInput Props::", props)
  return (
    <Form>
      <FormGroup>
        <Label for={inputDetails.id}>{inputDetails.text}</Label>
        <Input
          type="textarea"
          name={inputDetails.id}
          //placeholder={inputDetails.placeholder}
          value={inputValue}
          onChange={(event) => updateValue(inputDetails.id, event.target.value)}
          {...props}
        />
      </FormGroup>
    </Form>
  );
};

export default TextAreaInput;
