import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "reactstrap";
import { Div, H2, P, Image, Ul, Li } from "../elements";
import "./style.scss";

import heroBackground from "../../images/software/hero/happy_birthday_card_pexels-photo-2072181_sm.jpg";
import hero1 from "../../images/software/hero/1.png";

const Hero = ({ id }) => {
  return (
    <Div id={id} className="softwareHeroArea">
      <Container>
        <Row>
          <Col lg={7}>
            <Div className="softwareHeroImg">
              <Image src={heroBackground} alt="" />
            </Div>
          </Col>
          <Col lg={5}>
            <Div className="softwareHeroContent">
              <H2>
                The right <b>gift</b> at the right time
              </H2>
              <P>
                Gift giving made simple! It's an easy way to choose gifts that
                friends and family will be sure to enjoy, while sending
                reminders so birthdays won't be missed!
              </P>
              <Col lg={12}>
                <Button
                  className="m-1"
                  size="sm"
                  color="primary"
                  style={{ width: "100px" }}
                >
                  <Link to="/signup" style={{ color: "white" }}>
                    Get Started
                  </Link>
                </Button>
              </Col>
            </Div>
          </Col>
        </Row>
      </Container>
      <Div className="softwareHeroBgImg">
        <Image src={hero1} alt="" />
      </Div>
    </Div>
  );
};
export default Hero;
