import React, { Fragment, useState } from "react";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { useNavigate, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { H1, H3 } from "../components/elements";
import { UserSurvey } from "./GettingToKnow";
import { useAuthenticator } from "@aws-amplify/ui-react";
// TODO: Check if the person is logged in, if so confirm the right user is taking the survey.
//  If there is a Cognito User associted with the email address, ensure they login before taking the survey and it can only be taken by those who are logged in.

const triggerNotifySender = true;

function TellUsAboutYourselfHeader({ addressToName, sendersName }) {
  return (
    <Fragment>
      <H1 style={{ textAlign: "center", padding: 30 }}>
        Hi{" " + addressToName}!
      </H1>
      {sendersName ? sendersName + " " : "Your friend "}
      wants to give to give you a thoughtful gift! Please help them out by
      filling out the form below to give them a better idea of what you might
      want.
      <p />
    </Fragment>
  );
}

class TellUsAboutYourselfLogic extends UserSurvey {
  constructor(props) {
    super(props);
    this.state = {
      ...this.state,
      sendersName: props.sendersName,
      personSlug: props.personSlug,
      sendersUserID: props.sendersUserID,
      secondOrThirdPersonQuestion: "secondPerson", // Modify questions to use the second person form of the question.
    };
  }

  // componentDidMount( ) {
  //   super.componentDidMount();
  //   //const queryParams = new URLSearchParams(this.props.location.search);
  //   //const sendersName = queryParams.get('sendersName');
  // }

  render() {
    //console.log(this.state.nextPageURL)
    const nextPageUrl = this.state.nextPageURL;
    if (this.state.personNotFound) {
      return (
        <div>
          <H1>Person Not Found!</H1>
          <H3>Please refresh page or confirm the url has the persons name.</H3>
        </div>
      );
    } else
      return (
        <Container>
          <TellUsAboutYourselfHeader
            addressToName={this.state.currentPerson}
            sendersName={this.state.sendersName}
          />
          <p />
          <Row>
            <Col sm="12" md={{ size: 8, offset: 2 }}>
              {this.renderQuestionList()}
            </Col>
          </Row>
          <p />
          <Row>
            <Col sm="12" md={{ size: 6, offset: 8 }}>
              {this.state.savingResponses ? (
                <Spinner />
              ) : (
                <Button
                  onClick={() =>
                    this.clickSubmit(
                      nextPageUrl,
                      triggerNotifySender,
                      this.state.sendersUserID,
                    )
                  }
                  color="primary"
                  data-cy="submit-button"
                >
                  Submit
                </Button>
              )}
            </Col>
          </Row>
        </Container>
      );
  }
}

export default function TellUsAboutYourself() {
  const [isLoading, setIsLoading] = useState(true);
  const { personSlug } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const sendersName = searchParams.get("sendersName");
  const sendersUsername = searchParams.get("sendersUsername");
  const sendersUserID = searchParams.get("sendersUserID");
  const { route } = useAuthenticator();
  const isAuthenticated = route == "authenticated";

  return (
    <TellUsAboutYourselfLogic
      personSlug={personSlug}
      sendersName={sendersName}
      sendersUsername={sendersUsername}
      sendersUserID={sendersUserID}
      navigate={navigate}
      isAuthenticated={isAuthenticated}
      handleIsLoading={(isLoading) => setIsLoading(isLoading)}
      triggerNotifySender={true}
    />
  );
}
