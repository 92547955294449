import { Fragment } from "react";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
//import VideoPlayer from "../components/VideoPlayer";

const cloudFrontURL = "https://d1qof1s49x5tst.cloudfront.net/";

function WalkThrough() {
  const walkThroughVideoPath =
    cloudFrontURL + "Generous+Giver+Demo.mp4";
  //const walkThroughVideoS3Path = "s3://generous-giver-media/GenerousGiver_GettingStarted_2023-12-10_14-23-51.mp4"

  return (
    <Fragment>
      <h3>Learn how to Get Started</h3>
      <br />
      {/*<VideoPlayer s3PathToVideo={walkThroughVideoS3Path} /> */}
      <div className="video-container">
        <video width="100%" height="auto" controls>
          <source src={walkThroughVideoPath} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <br />
      <Button color="primary" style={{ width: "100px" }}>
        <Link to="/login" color="white">
          SIGN UP NOW
        </Link>
      </Button>
    </Fragment>
  );
}

export default WalkThrough;
