import { Fragment, useCallback, useState } from "react";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  addFriendAttributes,
  deleteUserFriendConnection,
  editFriendToUserProfile,
  saveFriendToUserProfile,
} from "../services/user";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const FriendNameModal = ({
  isOpen,
  onClose,
  newFriend,
  updateValue,
  isEdit = false,
  onSubmit,
  id,
}) => {
  const navigate = useNavigate();
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [deleteingUser, setDeleteingUser] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState("");
  const [deleteInputError, setDeleteInputError] = useState("");
  const [savingUser, setSavingUser] = useState(false);

  const handleDeleteIconClick = useCallback(async () => {
    setDeleteingUser(true);
    if (deleteInputValue === "delete") {
      try {
        await deleteUserFriendConnection(newFriend.userFriendId);
        setDeleteInputValue("");
        setIsOpenDeleteModal(false);
        setDeleteInputError("");
        onClose();
        onSubmit(false);
        toast.success("Deleted friend successfully!");
      } catch (err) {
        onSubmit(false);
        toast.error("Please try again to delete friend!");
        console.log(err);
      }
    } else {
      setDeleteInputError("Please enter correct word delete!");
    }
    setDeleteingUser(false);
    onSubmit(false);
  }, [deleteInputValue]);

  const handleEditSubmit = () => {
    setSavingUser(true);

    var friend = { ...newFriend };
    if (!friend.firstName) {
      toast.error("What's your friend's first name? Looks like it is missing.");
      setSavingUser(false);
      return;
    }

    var friend_clean = addFriendAttributes(friend);

    editFriendToUserProfile(id, friend_clean)
      .then(async (userFriendInfo) => {
        toast.success("Friend updated successfully!");
        // const updatedFriendsLists = await loadUser(id);
        // setFriendList(updatedFriendsLists.friends.items);
        setSavingUser(false);
        onSubmit(false);
      })
      .catch((err) => {
        console.log(err);
        setSavingUser(false);
        onSubmit(false);
      });

    // setNewFriend({
    //   firstName: "",
    //   lastName: "",
    //   email: "",
    //   birthday: "",
    //   personSlug: "",
    //   relation: "",
    // });
  };

  const handleSubmit = (keepModalVisible) => {
    setSavingUser(true);

    var friend = { ...newFriend };
    if (!friend.firstName) {
      toast.error("What's your friend's first name? Looks like it is missing.");
      setSavingUser(false);
      return;
    }

    var friend_clean = addFriendAttributes(friend);

    saveFriendToUserProfile(id, friend_clean)
      .then(async (userFriendInfo) => {
        toast.success(
          `Friend saved successfully! ${
            keepModalVisible
              ? "Please add another friend or click cancel to see the friend list."
              : ""
          }`,
        );

        setSavingUser(false);
        if (!keepModalVisible && userFriendInfo?.user?.personSlug) {
          navigate("/getting-to-know/" + userFriendInfo?.user?.personSlug);
        }
        onSubmit(keepModalVisible);
      })
      .catch((err) => {
        console.log(err);
        setSavingUser(false);
        onSubmit(false);
      });
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Get Started</ModalHeader>
        <ModalBody>
          <div style={{ paddingBottom: 10 }}>
            Which friend or family member would you like to give a gift to this
            year?
          </div>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for="firstName">Friend's First Name</Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="Samantha"
                value={newFriend.firstName}
                onChange={(event) =>
                  updateValue("firstName", event.target.value)
                }
              />
              <Label for="lastName">Friend's Last Name</Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Davis"
                value={newFriend.lastName}
                onChange={(event) =>
                  updateValue("lastName", event.target.value)
                }
              />
              <Label for="friendEmail">Friend's Email</Label>
              <Input
                type="email"
                name="friendEmail"
                id="friendEmail1"
                placeholder="sam@gmail.com"
                value={newFriend.email || ""}
                onChange={(event) => updateValue("email", event.target.value)}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Label for="birthday">Friend's Birthday</Label>
                <DatePicker
                  selected={newFriend.birthday}
                  onChange={(date) => updateValue("birthday", date)}
                  placeholderText="MM/DD/YYYY"
                  name="birthday"
                  id="birthday"
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                />
              </div>
              <Label for="name">How do you know them?</Label>
              <Input
                type="text"
                name="relation"
                id="relation1"
                placeholder="co-worker, brother, neighbor"
                value={newFriend.relation}
                onChange={(event) =>
                  updateValue("relation", event.target.value)
                }
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div onClick={() => setIsOpenDeleteModal(!isOpenDeleteModal)}>
              {isEdit && (
                <FontAwesomeIcon
                  icon={faTrash}
                  color="#ff0000"
                  data-cy="delete-icon"
                />
              )}
            </div>
            <div style={{ display: "flex", gap: "5px" }}>
              <Button color="secondary" onClick={onClose}>
                Cancel
              </Button>
              {savingUser ? (
                <Spinner />
              ) : isEdit ? (
                <Button
                  color="primary"
                  data-cy="save-button"
                  onClick={() => {
                    handleEditSubmit(false);
                  }}
                >
                  Save
                </Button>
              ) : (
                <div>
                  <Button
                    color="primary"
                    data-cy="next-button"
                    onClick={() => {
                      handleSubmit(true);
                    }}
                    style={{ marginRight: "5px" }}
                  >
                    Next
                  </Button>
                  <Button
                    color="secondary"
                    data-cy="done-button"
                    onClick={() => {
                      handleSubmit(false);
                    }}
                  >
                    Done
                  </Button>
                </div>
              )}
            </div>
          </div>
        </ModalFooter>
      </Modal>

      {/* Modal for delete the user friend */}
      <Modal
        isOpen={isOpenDeleteModal}
        toggle={() => setIsOpenDeleteModal(!isOpenDeleteModal)}
        className="modal-delete"
      >
        <ModalHeader toggle={onClose}>
          {`Are you sure you want to delete this user ${newFriend.firstName} ${newFriend.lastName}?`}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              <Label for="firstName">
                Type delete to delete friend from friend list.
              </Label>
              <Input
                type="text"
                name="deleteInputValue"
                id="deleteInputValue"
                placeholder="delete"
                value={deleteInputValue}
                onChange={(event) => setDeleteInputValue(event.target.value)}
              />
              <div style={{ color: "#ff0000" }}>{deleteInputError}</div>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <div
            style={{ display: "flex", justifyContent: "right", gap: "10px" }}
          >
            <Button
              color="secondary"
              onClick={() => setIsOpenDeleteModal(!isOpenDeleteModal)}
            >
              Cancel
            </Button>
            {deleteingUser ? (
              <Spinner />
            ) : (
              <Button
                color="primary"
                data-cy="delete-button"
                onClick={handleDeleteIconClick}
              >
                Delete
              </Button>
            )}
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default FriendNameModal;
