import React from "react";
import { APP_VERSION, GIT_COMMIT } from "../constants";

const AppVersion = () => {
  return (
    <div>
      <h3>App Version: {APP_VERSION}</h3>
      <h4>Git Hash: {GIT_COMMIT}</h4>
    </div>
  );
};

export default AppVersion;
