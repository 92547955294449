import { useNavigate } from "react-router-dom";
import React, { Fragment, useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { useParams } from "react-router";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { H1, H3 } from "../components/elements";
import TextAreaInput from "../components/TextAreaInput";
import { slugToName, slugToId } from "../utils";
import {
  loadQuestions,
  loadResponses,
  saveQuestionResponse,
  createSurveyResponseWrapper,
} from "../services/questionsAndResponses";
import { loadCognitoUser } from "../services/user";
import LoadingSpinner from "../components/LoadingSpinner";
import { getNotifySender } from "../services/notifySender";
import { generateGiftIdeas } from "../services/giftIdeaGenerator";
import { TRIGGER_NOTIFY_SENDER } from "../constants/featureFlags";
import { toast } from "react-toastify";

function GettingToKnowHeader({ addressToName }) {
  return (
    <div>
      <H1 style={{ textAlign: "center", padding: 30 }}>
        Getting to Know {addressToName ? addressToName : "your friend"}
      </H1>
      Your friends and family want to know how much you care and appreciate you
      taking the time to think of them. Gifts which are unique to each person
      and fit their needs are much more meaningful than generic presents. These
      questions are designed to help you think about your friend or family
      member and spark the creative juices to find something they might love!
      <p />
    </div>
  );
}

export class UserSurvey extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeQuestion: 0,
      redirectToNextPage: false,
      personNotFound: false,
      currentPerson: "",
      personSlug: "",
      questions: [],
      surveyID: null,
      secondOrThirdPersonQuestion: "thirdPerson", // Default for GettingToKnow component
      nextPageURL: "/signup",
      savingResponses: false,
    };
  }

  componentDidMount() {
    // TODO: Modify to useParams
    // https://reactrouter.com/en/main/hooks/use-params
    //   How can I use hooks within a class? Do I need to modify UserSurvey to be a function?
    this.setState({ viewer_username: this.props.sendersUsername });

    if (!this.props.personSlug) {
      this.setState({ personNotFound: true });
    } else {
      const personSlug = this.props.personSlug;
      const name = slugToName(personSlug);
      const currentPersonUserID = slugToId(personSlug);

      this.setState({
        currentPerson: name,
        currentPersonSlug: personSlug,
        currentPersonUserID,
      });
    }

    // Pull Information for friend given Slug
    // personSlug -> userDataKey -> userInfo?
    // New Table for user information.
    // id: personSlug
    loadQuestions()
      .then((questions) => {
        this.setState({ questions });
        if (questions) {
          questions.forEach((question) => {
            this.setState({ [question.id]: "" });
          });
        }
        this.loadUserAndResponses();
      })
      .catch((err) => console.log(err));
  }

  loadUserAndResponses = async () => {
    let { cognitoUserInfo, cognitoUserDataKey } = await loadCognitoUser();
    var takenByUserID = "";
    if (cognitoUserInfo) {
      this.setState({
        cognitoUserInfo,
        cognitoUserDataKey,
        nextPageURL: "/upcoming-birthdays",
      });
      takenByUserID = cognitoUserDataKey;
    } else {
      // Check if email of currentPersonUserID exists in Cognito OR User Database
      // If user found in cognito... ask to login
      // ELSE save uuid + timestamp + currentPersonUserID to localstorage
      takenByUserID = this.state.currentPersonUserID; // If the user doesn't exist, then use the currentPersonUserID (Assuming the person who was sent the survey will be completing it.)
    }

    try {
      const aboutUserID = this.state.currentPersonUserID;

      var surveyResponse = await loadResponses(takenByUserID, aboutUserID);

      if (surveyResponse) {
        const surveyID = surveyResponse.id;
        this.setState({ surveyID });

        const answers = surveyResponse.answers;
        if (answers) {
          const answerItems = answers.items;
          // Duplicating saving the results to state for now
          answerItems.forEach((responseObject) => {
            this.setState({
              [responseObject.question.id]: responseObject.response,
            });
            // Need the answer ids to see if an answer already exists and it should be updated. Move this to vtl?
            this.setState({ answerItems });
          });
        } else {
          // If there were no answers found.
          console.log("No answers found.");
        }
      } else {
        console.log(
          `No SurveyResponses found for takenbyUserID=${takenByUserID} and aboutUserID=${aboutUserID} Creating a new one.`,
        );
        // Create a new Date object for the current date and time
        const now = new Date();
        // Convert the date and time to ISO 8601 format
        const isoDateTime = now.toISOString();
        const title =
          "Getting To Know " + this.state.currentPerson + " " + isoDateTime;
        surveyResponse = await createSurveyResponseWrapper(
          takenByUserID,
          aboutUserID,
          title,
          this.props.isAuthenticated,
          this.state.viewer_username,
        );
        if (surveyResponse) {
          this.setState({ surveyID: surveyResponse.id });
        }
      }
      console.log("surveyID: ", this.state.surveyID);
      this.props.handleIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  findAnswerIdForQuestionId = (arrayOfAnswers, questionId) => {
    // Temporary function to look up the answerId for a given questionID due to the way state is storing the question and answers
    if (typeof arrayOfAnswers == "object") {
      return arrayOfAnswers.find(
        (item) => item.question && item.question.id === questionId,
      );
    }
    return null;
  };

  saveQuestionResponseFromState = async (questionId) => {
    const answerDetails = {
      surveyID: this.state.surveyID,
      answerQuestionId: questionId,
      response: this.state[questionId],
      viewer_username: this.state.viewer_username,
      //id:
    };
    // Look up answerId if it already exists in system
    const answer = this.findAnswerIdForQuestionId(
      this.state.answerItems,
      questionId,
    );
    if (answer) {
      answerDetails["id"] = answer.id;
      // Check if anything changed.
      if (answerDetails.response === answer.response) {
        return;
      }
    }

    return saveQuestionResponse(answerDetails, this.props.isAuthenticated);
  };

  clickSubmit = (nextPageUrl, triggerNotifySender = false, sendersUserID) => {
    console.log("Saving... ");
    const numAnswers = this.state.questions.filter((question) => {
      return this.state[question.id];
    }).length;
    if (numAnswers === 0) {
      alert(
        "Everything looks blank. Enter some info to continue... or press back.",
      );
      return;
    }
    this.setState({ savingResponses: true });
    //Save Response to each question.
    const savingResponsesPromises = this.state.questions
      .map((question) => {
        if (this.state[question.id]) {
          return this.saveQuestionResponseFromState(question.id);
        }
      })
      .filter((item) => {
        return item !== undefined;
      });
    console.log("savingResponsesPromises:: ", savingResponsesPromises);
    console.log(this.props);
    Promise.all(savingResponsesPromises)
      .then((results) => {
        console.log(results);
        this.setState({ savingResponses: false });
        const surveyID = this.state.surveyID;
        console.log("Gift Idea Generator started at ", new Date());
        generateGiftIdeas(surveyID)
          .then((res) => {
            console.log(res);
            console.log("Gift Idea Generator Finished at ", new Date());
          })
          .catch((err) => console.log(err));

        toast.success("Data are saved correctly!");

        if (triggerNotifySender && TRIGGER_NOTIFY_SENDER) {
          console.log("Saving Complete. Notifying sender!");
          getNotifySender(sendersUserID, surveyID)
            .then((res) => {
              console.log(res);
              if (res.name == "AxiosError") {
                console.log("Error notifying sender of request.");
              }
              this.setState({ savingResponses: false });
              this.props.navigate(nextPageUrl);
            })
            .catch((err) => {
              console.log(err);
              this.setState({ savingResponses: false });
            });
        } else {
          console.log("Saving Complete.");
          this.props.navigate(nextPageUrl);
        }
      })
      .catch((err) => console.log("ERROR while saving responses:: ", err));
  };

  sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  // Use to answer just one question at a time.
  // handleNextButton = () => {
  //   if (this.state.activeQuestion < this.state.questions.length - 1) {
  //     this.setState({activeQuestion: this.state.activeQuestion + 1})
  //   } else {
  //     this.props.history.push('/gift-ideas/' + this.state.currentPersonSlug)
  //   }
  // }

  renderBackButton = () => {
    if (this.state.activeQuestion > 0) {
      return (
        <Button
          color="secondary"
          onClick={() => {
            this.setState({ activeQuestion: this.state.activeQuestion - 1 });
          }}
        >
          Back
        </Button>
      );
    } else {
      return (
        <Button color="secondary" onClick={() => this.props.navigate(-1)}>
          Back
        </Button>
      );
    }
  };

  renderQuestionList = () => {
    if (!this.state.questions) return;
    return this.state.questions.map((question, index) => {
      if (this.state.secondOrThirdPersonQuestion === "secondPerson") {
        question.text = question.secondPersonForm;
      }

      return (
        <Row key={question.id}>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <TextAreaInput
              key={question.id}
              inputDetails={question}
              inputValue={this.state[question.id]}
              updateValue={this.updateValue}
              data-cy={question.text}
            />
          </Col>
        </Row>
      );
    });
  };

  renderQuestionsAndButtons = () => {
    return (
      <Fragment>
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            {this.renderQuestionList()}
          </Col>
        </Row>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            {this.renderBackButton()}
            {"  "}
            <Button
              onClick={() => this.clickSubmit("/upcoming-birthdays")}
              color="secondary"
              data-cy="save-for-later-button"
            >
              Save for Later
            </Button>
            {"  "}
            <Button
              color="primary"
              onClick={() =>
                this.clickSubmit(
                  "/user-gift-idea/" + this.state.currentPersonSlug,
                )
              }
              data-cy="add-gift-ideas-button"
            >
              Add Gift Ideas
            </Button>
          </Col>
        </Row>
      </Fragment>
    );
  };

  render() {
    if (this.state.personNotFound) {
      return (
        <div>
          <H1>Person Not Found!</H1>
          <H3>Please refresh page or confirm the url has the persons name.</H3>
        </div>
      );
    } else
      return (
        <Container>
          <GettingToKnowHeader addressToName={this.state.currentPerson} />
          <p />
          {this.props.isLoading ? (
            <LoadingSpinner />
          ) : (
            this.renderQuestionsAndButtons()
          )}
          <p />
        </Container>
      );
  }
}

function GettingToKnow() {
  const { personSlug } = useParams();
  const navigate = useNavigate();
  const { route } = useAuthenticator();
  const isAuthenticated = route == "authenticated";
  const [isLoading, setIsLoading] = useState(true);
  return (
    <UserSurvey
      personSlug={personSlug}
      navigate={navigate}
      isAuthenticated={isAuthenticated}
      isLoading={isLoading}
      handleIsLoading={(isLoading) => setIsLoading(isLoading)}
    />
  );
}

export default GettingToKnow;
