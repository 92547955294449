//import emailjs from "emailjs-com";

//import AWS from "aws-sdk";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Button,
  Row,
  Col,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faPenToSquare,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

import { checkGiftPurchasedRecently } from "../utils";
import { tellUsAboutYourselfPageURL } from "../constants";
import { CREATE_BIRTHDAY_MESSAGE_ON } from "../constants/featureFlags";

// Configure AWS credentials and region

// AWS.config.update({
//   accessKeyId: "da2-mwuwoprbdbdxho7dluezgj7n6u",
//   secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   region: process.env.REACT_APP_AWS_REGION,
//   apiVersion: "2012-08-10",
// });
//AWS.config.update({ region: "us-east-1" });
const displayRelation = (relation) => {
  return (
    <>
      Relation {relation} <br />
    </>
  );
};

const FriendProfileCard = ({
  personSlug,
  fullName,
  email,
  birthday,
  profileImageUrl,
  sendGift,
  relation,
  lastGiftPurchasedDateTime,
  recordGiftPurchased,
  personalityQuestionsAnswered,
  sendersName,
  sendersUsername,
  sendersUserID,
  handleEditIconClick,
}) => {
  const linkToSurvey =
    tellUsAboutYourselfPageURL +
    personSlug +
    "?sendersName=" +
    sendersName +
    "&sendersUsername=" +
    sendersUsername +
    "&sendersUserID=" +
    sendersUserID;
  const navigate = useNavigate();

  let birthdayReadable = "";
  if (typeof birthday !== String) {
    birthdayReadable = moment(birthday).format("MM/DD/YYYY");
  } else {
    birthdayReadable = birthday;
  }

  return (
    <Card key={personSlug} data-key={personSlug}>
      <CardBody className="text-center relative d-flex flex-column justify-content-between">
        <button
          style={{
            border: 0,
            color: "white",
            backgroundColor: "white",
            position: "absolute",
            right: "10px",
            top: "5px",
            marginBottom: "5px",
          }}
          onClick={handleEditIconClick}
          className="edit-icon"
        >
          <FontAwesomeIcon icon={faPenToSquare} color="#33d3ea" />
        </button>

        <Link to={"/gift-ideas/" + personSlug} className="mt-2">
          <CardTitle>{fullName}</CardTitle>
        </Link>
        <CardSubtitle data-cy="birthday" className="mb-2">
          {birthdayReadable !== "Invalid date" ? (
            birthdayReadable
          ) : (
            <button
              style={{ border: 0, backgroundColor: "white" }}
              onClick={handleEditIconClick}
            >
              Add birthday
            </button>
          )}
        </CardSubtitle>
        {profileImageUrl ? (
          <img width={100} src={profileImageUrl} alt={fullName} />
        ) : (
          ""
        )}
        <small>
          <CardSubtitle className="mb-2">
            {relation ? displayRelation(relation) : ""}
          </CardSubtitle>
          <CardSubtitle className="mb-2">
            Gift Purchased?&nbsp;
            {checkGiftPurchasedRecently(lastGiftPurchasedDateTime) ? (
              <FontAwesomeIcon icon={faCheckCircle} size="lg" color="green" />
            ) : (
              <button
                style={{ border: 0, color: "white", backgroundColor: "white" }}
                onClick={() => recordGiftPurchased(personSlug)}
              >
                <FontAwesomeIcon icon={faTimesCircle} size="lg" color="red" />
              </button>
            )}
          </CardSubtitle>
        </small>

        <CardSubtitle>
          <Button
            className="m-1"
            size="sm"
            color="primary"
            style={{ width: "100px" }}
            data-cy="getting-to-know"
            onClick={() => {
              navigate("/getting-to-know/" + personSlug);
            }}
          >
            Profile
          </Button>
          <Button
            className="m-1"
            size="sm"
            color="primary"
            style={{ width: "100px" }}
            onClick={() => {
              navigator.clipboard.writeText(
                window.location.origin + linkToSurvey,
              );
              alert(
                "Link Copied to Clipboard. Now you can send it to your friend to learn what they like!",
              );
            }}
          >
            Copy Link
          </Button>

          <Button
            className="m-1"
            size="sm"
            color="primary"
            style={{ width: "100px" }}
            data-cy="friend-survey-response-button"
            onClick={() => {
              navigate("/friend-survey-response/" + personSlug);
            }}
          >
            Responses
          </Button>
          {CREATE_BIRTHDAY_MESSAGE_ON ? (
            <Button
              className="m-1"
              size="sm"
              color="primary"
              style={{ width: "100px" }}
              data-cy="friend-survey-response-button"
              onClick={() => {
                if (!birthday)
                  toast.error("Please update birthday for this friend!");
                else navigate("/birthday-message/" + personSlug);
              }}
            >
              Message
            </Button>
          ) : (
            ""
          )}
          {/* <br />
          <Button
            size="sm"
            color="primary"
            onClick={() => {
              sendEmail(email, personSlug);
            }}
          >
            Send Mail
          </Button> */}
        </CardSubtitle>
      </CardBody>
    </Card>
  );
};

export default FriendProfileCard;
