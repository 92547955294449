import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Div, H2, P, Image } from "../elements";
import "./style.scss";

import about from "../../images/software/about/kelly-sikkema-wy0P13ZBxPM-unsplash.jpg";

const SoftwareAbout = ({ id }) => {
  return (
    <Div className="softwareAboutArea" id={id}>
      <Container>
        <Row>
          <Col lg={5}>
            <Div className="softwareAboutWrap">
              <H2>Show how much you care</H2>
              <P>
                With lots of close friends and family, it can be very difficult
                to keep track of all the birthdays, holidays and special events.
              </P>
              <P>
                GenerousGiver is here to help you by providing personalized gift
                recommendations for each person you care about weeks before the
                occasion.
              </P>
            </Div>
          </Col>
          <Col lg={7}>
            <Div className="softwareAboutImg">
              <Image src={about} alt="Photo by Kelly Sikkema on Unsplash" />
            </Div>
          </Col>
        </Row>
      </Container>
    </Div>
  );
};
export default SoftwareAbout;
