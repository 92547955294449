import { CognitoJwtVerifier } from "aws-jwt-verify";
import { API, Auth } from "aws-amplify";

export async function getCurrentUserToken() {
  //const user = await Auth.currentAuthenticatedUser();
  //const token = user.signInUserSession.idToken.jwtToken;
  const session = await Auth.currentSession();
  const token = session.getIdToken().getJwtToken();
  return token;
}

export async function verifyToken() {
  // Verifier that expects valid access tokens:
  const verifier = CognitoJwtVerifier.create({
    userPoolId: "us-east-1_mCfCwDgD6",
    tokenUse: "id",
    clientId: "3sga1feve0cesq5itjf1sgisvp",
    includeRawJwtInErrors: true,
  });
  const token = await getCurrentUserToken();

  try {
    const payload = await verifier.verify(token);
    console.log("Token is valid. Payload:", payload);
  } catch (err) {
    console.log("Token not valid!", err);
  }
}
