import moment from "moment";
import { AMAZON_LINK_CODE, AMAZON_TAG } from "../constants";
import { updateUserFriendConnection } from "../services/user";

export const convertListToObject = (list) => {
  return list.reduce((accumulator, item) => {
    accumulator[item.name] = item;
    return accumulator;
  }, {});
};

export const slugToName = (text) => {
  return text
    .toLowerCase()
    .split("__")
    .slice(0, 2)
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(" ");
};

export const slugToId = (personSlug) => {
  let slugParts = personSlug.split("__");

  return slugParts[slugParts.length - 1];
};

export const nameToSlug = (firstName, lastName, id) => {
  var slug = firstName + "__" + lastName + "__" + id;
  return slug.toLowerCase();
};

//ascending
export const sortObjectArray = (array, key) => {
  return array.sort((a, b) => {
    return a[key] > b[key] ? 1 : -1;
  });
};

export const getFriendKey = (newFriend) => {
  // TODO: Brainstorm how to match cognitoUserDataKey with this ID...
  return nameToSlug(newFriend.firstName + " " + newFriend.lastName);
};

export const arrayToObjectKeys = (myArray, key) => {
  var myObject = {};
  if (!myArray) {
    return;
  }
  myArray.forEach((item) => {
    if (!item.personSlug) {
      console.log("Error: Missing personSlug from " + item);
    } else {
      myObject[item.personSlug] = item;
    }
  });
  return myObject;
};

export const removeBlankAttributes = (myObject) => {
  for (var propName in myObject) {
    if (
      myObject[propName] === null ||
      myObject[propName] === undefined ||
      myObject[propName] === ""
    ) {
      delete myObject[propName];
    } else if (Array.isArray(myObject[propName])) {
      if (myObject[propName].length === 0) {
        delete myObject[propName];
      }
    }
  }
  return myObject;
};

export const addBirthdayReadable = (friendList) => {
  if (!friendList) {
    return friendList;
  }
  return friendList.map((friend) => {
    friend.birthdayReadable = moment(friend.user.birthday).format("MM/DD/YYYY");
    return friend;
  });
};

export const checkGiftPurchasedRecently = (lastGiftPurchasedDateTime) => {
  //console.log(lastGiftPurchasedDateTime)
  const now = new Date();
  const days = 10 * 7;
  const tenWeeksAgo = new Date(now.getTime() - days * 24 * 60 * 60 * 1000);
  if (typeof lastGiftPurchasedDateTime === "string") {
    console.log("Error: Expected Date object received string");
    return false;
  }
  return lastGiftPurchasedDateTime > tenWeeksAgo;
};

export const getNextBirthday = (now, myDate) => {
  if (!myDate || !now || !(myDate instanceof Date) || !(now instanceof Date)) {
    throw new Error(
      "getNextBirthday: myDate and now must be valid Date objects.",
    );
  }

  const currentMonthDay = now.getMonth() * 100 + now.getDate();
  const birthdayMonthDay = myDate.getMonth() * 100 + myDate.getDate();

  let nextBirthdayYear = now.getFullYear();
  if (currentMonthDay > birthdayMonthDay) {
    nextBirthdayYear += 1;
  }

  const nextBirthday = new Date(
    nextBirthdayYear,
    myDate.getMonth(),
    myDate.getDate(),
    0,
    0,
    0,
    0,
  );
  return nextBirthday;
};

export const sortFriendsList = (now, friendList) => {
  // Sort a list of friends by their next upcoming birthday.
  if (!friendList || !friendList.length) {
    return friendList;
  }

  friendList = friendList.map((friend) => {
    if (typeof friend.user.birthday === "string") {
      friend.user.birthday = new Date(friend.user.birthday);
    }
    return friend;
  });

  return friendList.sort((friendA, friendB) => {
    if (!friendA.user.birthday) {
      return 1;
    }
    if (!friendB.user.birthday) {
      return -1;
    }

    const nextBirthdayA = getNextBirthday(now, friendA.user.birthday);
    const nextBirthdayB = getNextBirthday(now, friendB.user.birthday);

    return nextBirthdayA > nextBirthdayB
      ? 1
      : nextBirthdayA < nextBirthdayB
        ? -1
        : 0;
  });
};

//modify to use sortObjectArray listed above.
export function sortDescendingByUpdatedAt(array) {
  return array.sort((a, b) => {
    // Convert the updated_at strings to Date objects for comparison
    const dateA = new Date(a.updated_at);
    const dateB = new Date(b.updated_at);

    // For descending order, if dateB is earlier than dateA, sort dateA to an index lower than dateB (i.e., dateA comes first)
    return dateB - dateA;
  });
}

export function extractUsername(string) {
  // Regular Expression to extract the username from a Cognito UserId
  // Example:
  //  string: CognitoIdentityServiceProvider.4sblhivrdhbdhj40potdban66u.TestUser1128.userData
  //  return: TestUser1128
  //var pattern = /CognitoIdentityServiceProvider\.\w+\.(\w+)\.userData/;
  var pattern = /CognitoIdentityServiceProvider\.\w+\.(.*)\.userData/;
  var match = string.match(pattern);
  if (match) {
    return match[1];
  } else {
    return null;
  }
}

export function extractExternalIdFromUrl(url) {
  try {
    // const regexOfExternalId = /\/dp\/([A-Za-z0-9]+)/;
    const regexOfExternalId = /\/(?:dp\/|d\/)([A-Za-z0-9]+)/;
    const match = url.match(regexOfExternalId);
    if (match && match[1]) {
      const externalId = match[1];
      return externalId;
    } else return null;
  } catch (err) {
    console.log(err);
  }
}

export function extractExternalDomainFromUrl(url) {
  try {
    const urlObject = new URL(url);
    const domain = urlObject.hostname;
    const domainParts = domain.split(".");
    const mainDomain = domainParts.slice(-2).join(".");
    return mainDomain || "";
  } catch (err) {
    console.log(err);
  }
}

export function addParamsToAmazonProductUrl(url) {
  if (!url) return;
  const separator = url.includes("?") ? "&" : "?";
  return `${url}${separator}${`linkCode=${AMAZON_LINK_CODE}&tag=${AMAZON_TAG}`}`;
}

export async function recordGiftPurchase(currentUserFriendId) {
  const userFriendAttributes = {
    lastGiftPurchasedDateTime: new Date(),
  };
  try {
    return await updateUserFriendConnection(
      currentUserFriendId,
      userFriendAttributes,
    );
  } catch (err) {
    console.log(err);
    return false;
  }
}
