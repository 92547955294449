import { InputGroupText, InputGroup, Input } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";

const renderFriendList = (friendList, handleCheckBox, handleEditIcon) => {
  //console.log(friendList)
  return Object.keys(friendList).map((friendKey) => {
    //console.log(friendKey)
    var friendInfo = {};
    try {
      friendInfo = {
        firstName: friendList[friendKey].user.firstName || "",
        lastName: friendList[friendKey].user.lastName || "",
        birthdayReadable: friendList[friendKey].birthdayReadable || "",
        selected: friendList[friendKey].selected || false,
      };
    } catch (err) {
      console.log(err);
      return null;
    }
    return (
      <li key={friendKey}>
        <InputGroup className="relative">
          <InputGroupText>
            <Input
              addon
              type="checkbox"
              aria-label="Include friend in gift list?"
              checked={friendInfo.selected}
              onChange={() => {
                handleCheckBox(friendKey);
              }}
            />
          </InputGroupText>
          <Input
            placeholder={friendInfo.firstName + " " + friendInfo.lastName}
            readOnly
          />
          <Input placeholder={friendInfo.birthdayReadable} readOnly />
          <div
            className="position-absolute end-0 p-2"
            style={{ height: "100%" }}
            onClick={() => {
              handleEditIcon(friendKey);
            }}
            data-cy="choose-whom-edit-icon"
          >
            <FontAwesomeIcon icon={faPenToSquare} color="#33d3ea" />
          </div>
        </InputGroup>
        <br />
      </li>
    );
  });
};

const FriendList = ({ friendList, handleCheckBox, handleEditIcon }) => {
  return (
    <div>
      <ul>{renderFriendList(friendList, handleCheckBox, handleEditIcon)}</ul>
    </div>
  );
};

export default FriendList;
