export const createSurveyResponseLimitedAccess = `
mutation CreateSurveyResponse(
  $input: CreateSurveyResponseInput!
  $condition: ModelSurveyResponseConditionInput
) {
  createSurveyResponse(input: $input, condition: $condition) {
    id
    title
    description
    takenByUserID
    aboutUserID
    createdAt
    updatedAt
    __typename
  }
}
`;
