import { API, graphqlOperation } from "aws-amplify";
import { listProducts } from "../graphql/queries";
import { createProduct, deleteProduct } from "../graphql/mutations";

export async function getAllProducts() {
  let allResponses = [];
  try {
    let nextToken = null;
    do {
      let response = await fetchProducts(nextToken);
      if (response.data.listProducts.items.length > 0) {
        allResponses = allResponses.concat(response.data.listProducts.items);
      }
      nextToken = response.data.listProducts.nextToken;
    } while (nextToken);

    return allResponses;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchProducts(nextToken) {
  try {
    let response = await API.graphql({
      query: listProducts,
      variables: { limit: 1000, nextToken },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const addProduct = async (productDetail) => {
  //console.log("Adding New Product.");
  try {
    const response = await API.graphql(
      graphqlOperation(createProduct, productDetail),
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const deleteProducts = async (id) => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteProduct, {
        input: { id: id },
      }),
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
