import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import awsconfig from "./aws-exports";
import AppRoutes from "./AppRoutes";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Dynamically set the redirect URI based on the current url.
awsconfig.oauth.redirectSignIn = `${window.location.origin}/`;
awsconfig.oauth.redirectSignOut = `${window.location.origin}/`;

Amplify.configure(awsconfig);

Auth.configure(awsconfig);

const App = () => {
  // const signOut = () => {
  //   Auth.signOut().then(() => {
  //     console.log("Signed out...");
  //     navigate('/login');
  //   }).catch((err) => {
  //     console.log("Error signing out.", err);
  //   });
  // };
  return (
    <Authenticator.Provider>
      <BrowserRouter>
        <AppRoutes />
        <ToastContainer
          autoClose="1500"
          toastClassName="custom-toast"
          bodyClassName="custom-toast-body"
        />
      </BrowserRouter>
    </Authenticator.Provider>
  );
};

export default App;
