// RequireAuth.js
import { useState, useEffect } from "react";
import { useLocation, Navigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Spinner, Container, Row, Col } from "reactstrap";
import moment from "moment";

import { UserContext } from "../../UserContext";
import { loadUser } from "../../services/user";

export default function RequireAuth({ children }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  const [currentUser, setCurrentUser] = useState({
    personSlug: "",
    firstName: "",
    lastName: "",
    email: "",
    addressStreet1: "",
    addressStreet2: "",
    addressCity: "",
    addressState: "",
    addressZip: "",
    birthday: "",
    birthdayReadable: "",
    id: "",
  });

  if (route !== "authenticated") {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  const loadUserData = (userId) => {
    loadUser(userId)
      .then((userData) => {
        if (!userData) {
          console.log("No user found.");
          setIsLoaded(true);
          return;
        }

        console.log("User Found.", userId);

        userData.birthdayReadable = moment(userData.birthday).format(
          "MM/DD/YYYY",
        );
        userData.onBoardingComplete = true;
        //console.log(userData)
        setCurrentUser(userData);
        setIsLoaded(true);
      })
      .catch((err) => {
        // Note: Data can also be returned.
        // { data: {...}, errors: [] }
        // TODO: Update error catching to return partial data when relevant.
        console.log(`Error loading User ${userId}.`);
        console.log(err);
        setIsLoaded(true);
      });
  };

  const getCurrentUserDynamo = () => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        var updatedUser = {
          id: user.userDataKey,
          email: user.attributes.email,
        };
        setCurrentUser(updatedUser);
        loadUserData(user.userDataKey);
      })
      .catch((res) => {
        console.log(res);
        setIsLoaded(true);
      });
  };

  useEffect(() => {
    getCurrentUserDynamo();
  }, [route]);

  const updateUser = (key, value) => {
    setCurrentUser({ ...currentUser, [key]: value });
  };

  var context = {
    currentUser,
    updateUser,
  };

  if (!isLoaded) {
    return (
      <Container style={{ marginTop: 200 }}>
        <Row>
          <Col>
            <center>
              <Spinner
                color="primary"
                style={{ width: "8rem", height: "8rem" }}
              />
            </center>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <UserContext.Provider value={context}>{children}</UserContext.Provider>
  );
}
