import React from "react";
import GiftIdeas from "./GiftIdeas";
import { useLocation } from "react-router-dom";

const FriendGiftIdeas = () => {
  const { state } = useLocation();
  const responsesByUserOrFriend =
    state && state.responsesByUserOrFriend !== null
      ? state.responsesByUserOrFriend
      : "friend";

  return <GiftIdeas responsesByUserOrFriend={responsesByUserOrFriend} />;
};

export default FriendGiftIdeas;
