import React from "react";
import { Container, Row, Col } from "reactstrap";
import { Div, H3, P } from "../elements";
import CountUp from "react-countup";
import "./style.scss";

const featureds = [
  {
    count: 1,
    title: "Choose Whom",
    text: "Import your friends from Facebook or manually enter them and select the people you'd like to buy birthday gifts for when the time is right",
  },
  {
    count: 2,
    title: "Be Reminded",
    text: "Receive an email or notification each week with the birthdays coming up in the next four weeks and custom gift recommendations just for them.",
  },
  {
    count: 3,
    title: "Purchase Gifts",
    text: "Simply select one of the gift recommendations and purchase it online within just a few clicks! Celebrate with your friends when the day arrives!",
  },
];

const FeaturedComponent = ({ className }) => {
  return (
    <Div className={className}>
      <Container>
        <Row>
          <Col lg={{ size: 4, offset: 4 }}>
            <H3>How it works</H3>
          </Col>
        </Row>
        <Row>
          {featureds.map((featured, i) => (
            <Col key={i} lg={4} md={6} xs={12}>
              <Div className="featuredWrap">
                <CountUp duration={2} end={featured.count} />
                <H3>{featured.title}</H3>
                <P>{featured.text}</P>
              </Div>
            </Col>
          ))}
        </Row>
      </Container>
    </Div>
  );
};
export default FeaturedComponent;
