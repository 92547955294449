import "./style.css";

const CustomSwitch = ({ id, label, checked, onChange }) => {
  //console.log({ checked });
  return (
    <div className="custom-switch">
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={() => {
          onChange(!checked);
        }}
      />
      <label htmlFor={id}>
        <span className="switch-label">{label}</span>
        <span className="switch-handle"></span>
      </label>
    </div>
  );
};

export default CustomSwitch;
