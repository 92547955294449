import React from "react";
import { Row, Col, Spinner } from "reactstrap";

const LoadingSpinner = () => {
  return (
    <Row>
      <Col sm="12" md={{ size: 1, offset: 6 }}>
        <p />
        <Spinner />
        <p />
      </Col>
    </Row>
  );
};

export default LoadingSpinner;
