import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import NotFoundPageImage from "../images/NotFoundPage.png";
const NotFoundPage = () => {
  return (
    <Container>
      <Row>
        <Col xs={12}>
          <img
            src={NotFoundPageImage}
            alt="Page not found"
            className="img-fluid"
            style={{ height: "600px", objectFit: "cover" }}
          />
        </Col>
        <Col xs={12}>
          <Link to="/" style={{ fontSize: "22px", fontWeight: 600 }}>
            Go back to home
          </Link>
        </Col>
      </Row>
    </Container>
  );
};
export default NotFoundPage;
