import axios from "axios";
import { GIFT_IDEA_API_URL } from "../config";
import { getCurrentUserToken } from "../containers/auth/verifyAccessToken.mjs";
import { TRIGGER_GIFT_IDEA_GENERATOR } from "../constants/featureFlags";

export async function generateGiftIdeas(surveyId) {
  if (!TRIGGER_GIFT_IDEA_GENERATOR) {
    console.log("Gift Idea Generated Turned off in feature Flags. Skipping...");
    return;
  }
  // The URL of the FastAPI app on the local development server
  const url = `${GIFT_IDEA_API_URL}`;
  console.log("Calling: ", url);
  const data = {
    survey_id: surveyId,
  };

  const token = await getCurrentUserToken();
  // Sending a POST request to the API endpoint
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  try {
    // Sending a POST request to the API endpoint using Axios
    const response = await axios.post(url, data, config);

    // Handling the success response
    console.log("Success! Received response from the server:");
    console.log(response.data); // Printing the data received from the server
    return response;
  } catch (error) {
    // Error handling
    console.error(`Error. Status code: ${error.response.status}`);
    console.error(error.response.data);
    return error;
  }
}
