import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { H2 } from "../components/elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import AddProductModal from "../components/AddProductModal";
import {
  extractExternalDomainFromUrl,
  extractExternalIdFromUrl,
} from "../utils";
import { addProduct, getAllProducts } from "../services/products";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      newProduct: {
        externalId: "",
        externalUrl: "",
        title: "",
        description: "",
        productImageUrl: "",
        rating: null,
        price: "",
        currency: "",
      },
      loading: true,
      productModalVisible: false,
      savingData: false,
    };
  }

  async componentDidMount() {
    console.log("did mount");
    try {
      const products = await getAllProducts();
      console.log("in did mount product container res: ", products);
      this.setState({
        products: products || [],
        loading: false,
      });
    } catch (error) {
      console.error(error);
      // Handle the error, e.g., set an error state
    }
  }

  handleCloseModal = () => {
    this.setState({ productModalVisible: false });
  };

  clearNewProductFields = () => {
    this.setState({
      newProduct: {
        externalId: "",
        externalUrl: "",
        title: "",
        description: "",
        productImageUrl: "",
        rating: null,
        price: "",
      },
    });
  };

  addNewProduct = async (closeModal) => {
    this.setState({ savingData: true });

    const externalId = extractExternalIdFromUrl(
      this.state.newProduct.externalUrl,
    );

    const externalDomain = extractExternalDomainFromUrl(
      this.state.newProduct.externalUrl,
    );

    const updatedNewProduct = {
      ...this.state.newProduct,
      externalId: externalId,
      externalDomain: externalDomain,
    };

    const productDetail = {
      input: updatedNewProduct,
    };

    try {
      const response = await addProduct(productDetail);

      if (response && response.data && response.data.createProduct) {
        const newProductFromResponse = response.data.createProduct;
        const products = [...this.state.products, newProductFromResponse];

        this.setState({ products, savingData: false });
        this.clearNewProductFields();

        if (closeModal) {
          this.setState({ productModalVisible: false });
        }
      } else {
        console.error("Unexpected GraphQL response:", response);
        this.setState({ savingData: false });
      }
    } catch (error) {
      console.error("Error adding product:", error);
      this.setState({ savingData: false });
    }
  };

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  renderProducts = () => {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        {this.state.products.map((product, index) => {
          return (
            <div key={index}>
              <Row>
                <Col>{product.title}</Col>
                <Col>
                  {product.description?.substring(0, 80)}
                  {product.description?.length > 80 ? "..." : ""}
                </Col>
              </Row>
              <p />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <Container>
        <AddProductModal
          isOpen={this.state.productModalVisible}
          onClose={this.handleCloseModal}
          onSubmit={this.addNewProduct}
          newProduct={this.state.newProduct}
          updateValue={this.updateValue}
          savingData={this.state.savingData}
        />
        <div style={{ marginTop: 30 }}>
          <Row>
            <H2>Products</H2>
          </Row>
          <Row>
            <Col>{this.renderProducts()}</Col>
          </Row>
          <Row>
            <Col>
              <Button
                onClick={() => {
                  this.setState({ productModalVisible: true });
                }}
                style={{ backgroundColor: "white", borderWidth: 0 }}
              >
                <FontAwesomeIcon
                  icon={faPlusCircle}
                  size="lg"
                  color="#33d3ea"
                />
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default Products;
