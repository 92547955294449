/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      personSlug
      firstName
      lastName
      email
      phone
      addressStreet1
      addressStreet2
      addressCity
      addressState
      addressZip
      addressCountry
      birthday
      annualBudget
      createdAt
      updatedAt
      description
      friends {
        nextToken
        __typename
      }
      viewers
      daysBetweenReminders
      maxDaysBeforeEventToRemind
      sendEmailBirthdayReminders
      subscriptionPlan
      subscriptionStatus
      subscriptionStartedAt
      paymentGatewayId
      profileImageUrl
      surveysAboutUser {
        nextToken
        __typename
      }
      surveysTaken {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      personSlug
      firstName
      lastName
      email
      phone
      addressStreet1
      addressStreet2
      addressCity
      addressState
      addressZip
      addressCountry
      birthday
      annualBudget
      createdAt
      updatedAt
      description
      friends {
        nextToken
        __typename
      }
      viewers
      daysBetweenReminders
      maxDaysBeforeEventToRemind
      sendEmailBirthdayReminders
      subscriptionPlan
      subscriptionStatus
      subscriptionStartedAt
      paymentGatewayId
      profileImageUrl
      surveysAboutUser {
        nextToken
        __typename
      }
      surveysTaken {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      personSlug
      firstName
      lastName
      email
      phone
      addressStreet1
      addressStreet2
      addressCity
      addressState
      addressZip
      addressCountry
      birthday
      annualBudget
      createdAt
      updatedAt
      description
      friends {
        nextToken
        __typename
      }
      viewers
      daysBetweenReminders
      maxDaysBeforeEventToRemind
      sendEmailBirthdayReminders
      subscriptionPlan
      subscriptionStatus
      subscriptionStartedAt
      paymentGatewayId
      profileImageUrl
      surveysAboutUser {
        nextToken
        __typename
      }
      surveysTaken {
        nextToken
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createUserFriend = /* GraphQL */ `
  mutation CreateUserFriend(
    $input: CreateUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    createUserFriend(input: $input, condition: $condition) {
      id
      primaryUserID
      user {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      relation
      selected
      lastGiftPurchasedDateTime
      generalGiftIdeas {
        description
        active
        createdAt
        createdByID
        __typename
      }
      giftRecommendations {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateUserFriend = /* GraphQL */ `
  mutation UpdateUserFriend(
    $input: UpdateUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    updateUserFriend(input: $input, condition: $condition) {
      id
      primaryUserID
      user {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      relation
      selected
      lastGiftPurchasedDateTime
      generalGiftIdeas {
        description
        active
        createdAt
        createdByID
        __typename
      }
      giftRecommendations {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteUserFriend = /* GraphQL */ `
  mutation DeleteUserFriend(
    $input: DeleteUserFriendInput!
    $condition: ModelUserFriendConditionInput
  ) {
    deleteUserFriend(input: $input, condition: $condition) {
      id
      primaryUserID
      user {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      relation
      selected
      lastGiftPurchasedDateTime
      generalGiftIdeas {
        description
        active
        createdAt
        createdByID
        __typename
      }
      giftRecommendations {
        nextToken
        __typename
      }
      messages {
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createGiftRecommendation = /* GraphQL */ `
  mutation CreateGiftRecommendation(
    $input: CreateGiftRecommendationInput!
    $condition: ModelGiftRecommendationConditionInput
  ) {
    createGiftRecommendation(input: $input, condition: $condition) {
      id
      userFriendID
      product {
        id
        externalId
        externalUrl
        externalDomain
        title
        description
        productImageUrl
        rating
        price
        currency
        createdAt
        updatedAt
        __typename
      }
      viewed
      purchasedOn
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateGiftRecommendation = /* GraphQL */ `
  mutation UpdateGiftRecommendation(
    $input: UpdateGiftRecommendationInput!
    $condition: ModelGiftRecommendationConditionInput
  ) {
    updateGiftRecommendation(input: $input, condition: $condition) {
      id
      userFriendID
      product {
        id
        externalId
        externalUrl
        externalDomain
        title
        description
        productImageUrl
        rating
        price
        currency
        createdAt
        updatedAt
        __typename
      }
      viewed
      purchasedOn
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteGiftRecommendation = /* GraphQL */ `
  mutation DeleteGiftRecommendation(
    $input: DeleteGiftRecommendationInput!
    $condition: ModelGiftRecommendationConditionInput
  ) {
    deleteGiftRecommendation(input: $input, condition: $condition) {
      id
      userFriendID
      product {
        id
        externalId
        externalUrl
        externalDomain
        title
        description
        productImageUrl
        rating
        price
        currency
        createdAt
        updatedAt
        __typename
      }
      viewed
      purchasedOn
      active
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateSurveyResponse = /* GraphQL */ `
  mutation UpdateSurveyResponse(
    $input: UpdateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    updateSurveyResponse(input: $input, condition: $condition) {
      id
      title
      description
      takenByUserID
      takenBy {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      aboutUserID
      aboutUser {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      answers {
        nextToken
        __typename
      }
      viewers
      aiGiftIdeaLastRunAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteSurveyResponse = /* GraphQL */ `
  mutation DeleteSurveyResponse(
    $input: DeleteSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    deleteSurveyResponse(input: $input, condition: $condition) {
      id
      title
      description
      takenByUserID
      takenBy {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      aboutUserID
      aboutUser {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      answers {
        nextToken
        __typename
      }
      viewers
      aiGiftIdeaLastRunAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      secondPersonForm
      text
      thirdPersonForm
      placeholder
      active
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      secondPersonForm
      text
      thirdPersonForm
      placeholder
      active
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      secondPersonForm
      text
      thirdPersonForm
      placeholder
      active
      sortOrder
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer(
    $input: UpdateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    updateAnswer(input: $input, condition: $condition) {
      id
      surveyID
      response
      giftIdea
      aiGiftIdea
      viewers
      createdAt
      updatedAt
      question {
        id
        secondPersonForm
        text
        thirdPersonForm
        placeholder
        active
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const deleteAnswer = /* GraphQL */ `
  mutation DeleteAnswer(
    $input: DeleteAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    deleteAnswer(input: $input, condition: $condition) {
      id
      surveyID
      response
      giftIdea
      aiGiftIdea
      viewers
      createdAt
      updatedAt
      question {
        id
        secondPersonForm
        text
        thirdPersonForm
        placeholder
        active
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
export const createProduct = /* GraphQL */ `
  mutation CreateProduct(
    $input: CreateProductInput!
    $condition: ModelProductConditionInput
  ) {
    createProduct(input: $input, condition: $condition) {
      id
      externalId
      externalUrl
      externalDomain
      title
      description
      productImageUrl
      rating
      price
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const updateProduct = /* GraphQL */ `
  mutation UpdateProduct(
    $input: UpdateProductInput!
    $condition: ModelProductConditionInput
  ) {
    updateProduct(input: $input, condition: $condition) {
      id
      externalId
      externalUrl
      externalDomain
      title
      description
      productImageUrl
      rating
      price
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const deleteProduct = /* GraphQL */ `
  mutation DeleteProduct(
    $input: DeleteProductInput!
    $condition: ModelProductConditionInput
  ) {
    deleteProduct(input: $input, condition: $condition) {
      id
      externalId
      externalUrl
      externalDomain
      title
      description
      productImageUrl
      rating
      price
      currency
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      userFriendID
      qna {
        question
        answer
        __typename
      }
      message
      aiMessage
      scheduledToSendOn
      messageSentOn
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      userFriendID
      qna {
        question
        answer
        __typename
      }
      message
      aiMessage
      scheduledToSendOn
      messageSentOn
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      userFriendID
      qna {
        question
        answer
        __typename
      }
      message
      aiMessage
      scheduledToSendOn
      messageSentOn
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createSurveyResponse = /* GraphQL */ `
  mutation CreateSurveyResponse(
    $input: CreateSurveyResponseInput!
    $condition: ModelSurveyResponseConditionInput
  ) {
    createSurveyResponse(input: $input, condition: $condition) {
      id
      title
      description
      takenByUserID
      takenBy {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      aboutUserID
      aboutUser {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        viewers
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        owner
        __typename
      }
      answers {
        nextToken
        __typename
      }
      viewers
      aiGiftIdeaLastRunAt
      createdAt
      updatedAt
      owner
      __typename
    }
  }
`;
export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer(
    $input: CreateAnswerInput!
    $condition: ModelAnswerConditionInput
  ) {
    createAnswer(input: $input, condition: $condition) {
      id
      surveyID
      response
      giftIdea
      aiGiftIdea
      viewers
      createdAt
      updatedAt
      question {
        id
        secondPersonForm
        text
        thirdPersonForm
        placeholder
        active
        sortOrder
        createdAt
        updatedAt
        __typename
      }
      owner
      __typename
    }
  }
`;
