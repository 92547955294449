import React, { Fragment } from "react";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

// amazonId: String
// amazonUrl: String
// title: String
// description: String
// productImageUrl: String
// rating: Int

const productFields = {
  externalId: {
    label: "Product Id",
    type: "text",
    name: "externalId",
    id: "externalId",
    placeholder: "B07W85KVMP",
  },
  externalUrl: {
    label: "Product URL",
    type: "text",
    name: "externalUrl",
    id: "externalUrl",
    placeholder: "https://www.amazon.com/dp/B07W85KVMP/",
  },
  title: {
    label: "Product Name",
    type: "text",
    name: "title",
    id: "title",
    placeholder: "Newtion Lighting Earbuds Headphone Wired Earphones",
  },
  description: {
    label: "Product Description",
    type: "text",
    name: "description",
    id: "description",
    placeholder:
      "Compatible with iPhone 7/7 Plus/iPhone 8/8Plus / iPhone X 10/iPhone XS / XR / XS Max Earphones, Support IOS 10/11/12 All System，Plug and Play!!!",
  },
  productImageUrl: {
    label: "Product Image URL",
    type: "text",
    name: "productImageUrl",
    id: "productImageUrl",
    placeholder:
      "https://images-na.ssl-images-amazon.com/images/I/51InBFGeQKL._AC_SL1000_.jpg",
  },
  rating: {
    label: "Rating",
    type: "int",
    name: "rating",
    id: "rating",
    placeholder: "4",
  },
  price: {
    label: "Price",
    type: "int",
    name: "price",
    id: "price",
    placeholder: "499",
  },
  currency: {
    label: "Currency",
    type: "text",
    name: "currency",
    id: "currency",
    placeholder: "USD",
  },
};

const renderInputField = (index, inputParams, newProduct, updateValue) => {
  return (
    inputParams?.id !== "externalId" && (
      <div key={index}>
        <Label for={inputParams.name}>{inputParams.label}</Label>
        <Input
          type={inputParams.type}
          name={inputParams.name}
          id={inputParams.id}
          placeholder={inputParams.placeholder}
          value={newProduct[inputParams.name]}
          onChange={(event) =>
            updateValue("newProduct", {
              ...newProduct,
              [inputParams.name]: event.target.value,
            })
          }
        />
      </div>
    )
  );
};

const AddProductModal = ({
  isOpen,
  onClose,
  onSubmit,
  newProduct,
  updateValue,
  savingData,
}) => {
  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>Add Product</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              {Object.keys(newProduct).map((productField, index) => {
                return renderInputField(
                  index,
                  productFields[productField],
                  newProduct,
                  updateValue,
                );
              })}
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          {savingData ? (
            <Spinner />
          ) : (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  onSubmit(false);
                }}
              >
                Next
              </Button>{" "}
              <Button
                color="secondary"
                onClick={() => {
                  onSubmit(true);
                }}
              >
                Done
              </Button>
            </div>
          )}
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddProductModal;
