import { API, graphqlOperation } from "aws-amplify";

import { getGiftRecommendation } from "../graphql/queries";
import {
  getUserFriend,
  listGiftRecommendations,
} from "../graphql/queries_manual";

import {
  createGiftRecommendation,
  deleteGiftRecommendation,
} from "../graphql/mutations";

export const listAllGiftRecommendations = async (
  giftRecommendationProductId,
  userFriendID,
) => {
  const allResponses = await getAllGiftRecommendations(userFriendID);
  if (allResponses) {
    const response = allResponses.find((giftRecommendation) => {
      return (
        giftRecommendation.product &&
        giftRecommendation.product.id === giftRecommendationProductId
      );
    });
    if (response) return false;
    else return true;
  } else {
    return false;
  }
};

async function getAllGiftRecommendations(userFriendID) {
  let allResponses = [];
  try {
    let nextToken = null;
    do {
      let response = await fetchGiftRecommendations(userFriendID, nextToken);
      if (response.data.listGiftRecommendations.items.length > 0) {
        allResponses = allResponses.concat(
          response.data.listGiftRecommendations.items,
        );
      }
      nextToken = response.data.listGiftRecommendations.nextToken;
    } while (nextToken);

    return allResponses;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchGiftRecommendations(userFriendID, nextToken) {
  const filter = {
    userFriendID: { eq: userFriendID },
  };
  try {
    let response = await API.graphql({
      query: listGiftRecommendations,
      variables: { filter, limit: 1000, nextToken },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

const loadGiftRecommendations = async (giftRecommendationID) => {
  try {
    const res = await API.graphql(
      graphqlOperation(getGiftRecommendation, { id: giftRecommendationID }),
    );
    return res.data.getGiftRecommendations;
  } catch (err) {
    console.log(err);
  }
};

export const getGiftRecommendationsForFriend = async (userFriendID) => {
  // userFriendID is the ID for the UserFriend model which connects a user and his friends.
  // The list of gifts can be accessed through this relationship. Each user/friend connection will have a
  //     list of gift recommendations that the user could select from to give to his friend.

  try {
    const res = await API.graphql(
      graphqlOperation(getUserFriend, { id: userFriendID }),
    );
    const userFriend = res.data.getUserFriend;
    const giftRecommendations = userFriend.giftRecommendations.items;
    //console.log(userFriend);
    return giftRecommendations;
  } catch (err) {
    console.log(err);
  }
};

export const createGiftRecommendationForFriend = async (
  giftRecommendationInput,
) => {
  //console.log("giftRecommendationInput: ", giftRecommendationInput);
  try {
    const newGiftRecommendationObj = await API.graphql(
      graphqlOperation(createGiftRecommendation, {
        input: giftRecommendationInput,
      }),
    );
    //console.log({ newGiftRecommendationObj });
    return newGiftRecommendationObj;
  } catch (err) {
    console.log(err);
  }
};

export const deleteGiftRecommendationForFriend = async (
  giftRecommendationID,
) => {
  try {
    const response = await API.graphql(
      graphqlOperation(deleteGiftRecommendation, {
        input: { id: giftRecommendationID },
      }),
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
