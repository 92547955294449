import React, { Fragment } from "react";
import PricingTable from "../components/PricingTable";
import FooterComponent from "../components/Footer";

const Pricing = () => {
  return (
    <Fragment>
      <PricingTable id="pricing" noGutters className="softwarePricingTable" />
      <FooterComponent className="footerArea bookFooterArea" />
    </Fragment>
  );
};
export default Pricing;
