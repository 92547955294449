import { API, graphqlOperation } from "aws-amplify";
import { listQuestions } from "../graphql/queries";
import { createAnswer, updateAnswer } from "../graphql/mutations";
import { createSurveyResponseLimitedAccess } from "../graphql/mutations_custom";
import { listSurveyResponses } from "../graphql/queries_manual";

import {
  sortObjectArray,
  sortDescendingByUpdatedAt,
  removeBlankAttributes,
} from "../utils";

export const loadQuestions = async (isAuthenticated) => {
  const filter = {
    active: {
      eq: true,
    },
  };
  try {
    const res = await API.graphql({
      query: listQuestions,
      variables: { filter },
      authMode: isAuthenticated ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY",
    });
    const questions = res.data.listQuestions.items;
    if (questions[0].sortOrder !== null)
      return sortObjectArray(questions, "sortOrder");
    else return sortObjectArray(questions, "id");
  } catch (err) {
    console.log(err);
  }
};

export const loadResponses = async (takenByUserID, aboutUserID) => {
  console.log("SurveyTakenByID: ", takenByUserID, "aboutUserID: ", aboutUserID);
  if (
    typeof takenByUserID === "undefined" ||
    typeof aboutUserID === "undefined"
  ) {
    console.log(
      "Unable to load responses.",
      takenByUserID,
      aboutUserID,
      " one of these are undefined.",
    );
    return [];
  }
  const filter = {
    takenByUserID: {
      eq: takenByUserID,
    },
    aboutUserID: {
      eq: aboutUserID,
    },
  };
  const allResponses = await getAllSurveyResponses(filter);
  return allResponses[0];
};

async function getAllSurveyResponses(filter) {
  let allResponses = [];
  try {
    let nextToken = null;
    do {
      let response = await fetchSurveyResponses(filter, nextToken);
      if (response.data.listSurveyResponses.items.length > 0) {
        allResponses = allResponses.concat(
          response.data.listSurveyResponses.items,
        );
      }
      nextToken = response.data.listSurveyResponses.nextToken;
    } while (nextToken);

    return allResponses;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchSurveyResponses(filter, nextToken) {
  try {
    let response = await API.graphql({
      query: listSurveyResponses,
      variables: { filter, limit: 1000, nextToken },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export const createSurveyResponseWrapper = async (
  takenByUserID,
  aboutUserID,
  title,
  isAuthenticated,
  viewer_username,
) => {
  const authMode = isAuthenticated ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY";
  console.log("authMode: ", authMode);
  const createSurveyResponseInput = {
    input: {
      takenByUserID,
      aboutUserID,
      title,
    },
  };
  if (viewer_username) {
    createSurveyResponseInput.input["viewers"] = [viewer_username];
  }

  //console.log("Initiated creation of SurveyResponse.")
  try {
    let res = await API.graphql({
      query: createSurveyResponseLimitedAccess,
      variables: createSurveyResponseInput,
      authMode: authMode,
    });
    //console.log(res)
    return res.data?.createSurveyResponse;
  } catch (res) {
    console.log("ERROR: ", res);
    return res.data?.createSurveyResponse;
  }
};

export const addAnswerWrapper = async (answerDetails, isAuthenticated) => {
  const createAnswerInput = {
    input: {
      surveyID: answerDetails.surveyID,
      answerQuestionId: answerDetails.answerQuestionId,
      response: answerDetails.response,
      viewers: [answerDetails.viewer_username],
    },
  };
  let res = await API.graphql({
    query: createAnswer,
    variables: createAnswerInput,
    authMode: isAuthenticated ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY",
  });
  return res.data.createAnswer;
};

const updateAnswerWrapper = async (answerDetails, isAuthenticated) => {
  //TODO: Determine best way to edit the viewer username if needed...
  delete answerDetails.viewer_username;

  const updateAnswerInput = {
    input: answerDetails,
  };
  let res = await API.graphql({
    query: updateAnswer,
    variables: updateAnswerInput,
    authMode: isAuthenticated ? "AMAZON_COGNITO_USER_POOLS" : "API_KEY",
  });
  return res.data.updateAnswer;
};

export const upsertAnswer = async (answerDetailsOriginal, isAuthenticated) => {
  console.log(answerDetailsOriginal);
  let answerDetails = removeBlankAttributes(answerDetailsOriginal);
  if (answerDetails.id) {
    console.log("Updating Answer:: ", answerDetails.id);
    return await updateAnswerWrapper(answerDetails, isAuthenticated);
  } else {
    return await addAnswerWrapper(answerDetails, isAuthenticated);
  }
};

export const saveQuestionResponse = async (answerDetails, isAuthenticated) => {
  // Add the survey answers to each of the questions in the survey, if they don't exist already?
  //console.log("SurveyID: ", surveyID, " Saving response: ", response)
  return await upsertAnswer(answerDetails, isAuthenticated);
};

export const saveUserGiftIdea = async (answerId, giftIdea, callback) => {
  const inputDetail = {
    input: {
      id: answerId,
      giftIdea,
    },
  };
  return await API.graphql(graphqlOperation(updateAnswer, inputDetail));
  //  .then( res => {
  //    console.log(res)
  //    callback()
  //  })
  //  .catch( err => console.log(err))
};
