import { Fragment } from "react";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Button,
  Label,
  Row,
  Col,
} from "reactstrap";
import { H5 } from "../components/elements";

const AddGiftRecommendationFromAmazon = ({
  isOpen,
  onClose,
  onSubmit,
  handleChange,
  savingData,
  amazonUrl,
  products,
}) => {
  const renderProducts = (products, onSubmit) => {
    return (
      <ul>
        {products.map((product) => {
          return (
            <div key={product.id}>
              <Row>
                <Col lg={{ size: 1 }}>
                  <Button
                    onClick={() => onSubmit(product.id)}
                    data-cy="add-product-select-from-list-button"
                  >
                    +
                  </Button>
                </Col>
                <Col>{product.title}</Col>
                <Col>
                  {product.description?.substring(0, 40)}
                  {product.description?.length > 40 ? "..." : ""}
                </Col>
              </Row>
              <p />
            </div>
          );
        })}
      </ul>
    );
  };

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>New Gift idea?</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmit}>
            <Label for="amazonUrl">Enter Amazon URL</Label>
            <FormGroup>
              <Input
                type="text"
                name="amazonUrl"
                id="amazonUrl"
                placeholder="https://www.amazon.com/dp/B07W85KVMP/"
                value={amazonUrl}
                onChange={handleChange}
                autoFocus={true}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={onClose}>
            Cancel
          </Button>
          {savingData ? (
            <Spinner />
          ) : (
            <div>
              <Button
                color="primary"
                onClick={() => {
                  onSubmit();
                }}
                data-cy="save-button"
              >
                Save
              </Button>
            </div>
          )}
          <div>
            <H5>Select Products to Recommend</H5>
            {renderProducts(products, onSubmit)}
          </div>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddGiftRecommendationFromAmazon;
