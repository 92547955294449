import React from "react";

const UserContext = React.createContext({ user: {} }); // Create a context object

export {
  UserContext, // Export it so it can be used by other Components
};

export const withUserContext = (Component) => {
  function componentWithContext(props) {
    return (
      <UserContext.Consumer>
        {({ currentUser, updateUser }) => (
          <Component
            {...props}
            currentUser={currentUser}
            updateUser={updateUser}
          />
        )}
      </UserContext.Consumer>
    );
  }
  return componentWithContext; // Return the component with the context object injected as a prop. This is the same as the original component, but with the context object injected as a prop. This is the same as the original component, but with the context object injected as a prop. This is the same as the original component,
};
