import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { H1, H3, H6 } from "../components/elements";
import { slugToName, slugToId } from "../utils";
import { Auth } from "aws-amplify";
import {
  loadQuestions,
  loadResponses,
  saveUserGiftIdea,
} from "../services/questionsAndResponses";
import { Form, FormGroup, Label, Input } from "reactstrap";
import LoadingSpinner from "../components/LoadingSpinner";
import { UserContext, withUserContext } from "../UserContext";
import { withRouter } from "../legacy/react-router-old";
import { addGeneralGiftIdeaToUserFriend } from "../services/userFriend";
import { isAllowFreeFormGiftIdeas } from "../constants/featureFlags";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const defaultWelcomeMessage =
  "Now that you've written down a few thoughts about your friend, what do you think they might want for their birthday?";

class UserGiftIdeas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      activeQuestion: 0,
      redirectToNextPage: false,
      personNotFound: false,
      currentPerson: "",
      currentPersonSlug: "",
      questions: [],
      giftIdeas: {},
      numAnswersFound: -1,
      answers: [],
      welcomeMessage: this.props.welcomeMessage || defaultWelcomeMessage,
      latestUpdatedAt: null,
      freeFromGiftIdeas: "",
      freeFromGiftIdeasList: null,
      currentUserFriendObj: null,
    };
  }

  async componentDidMount() {
    if (!this.props.match) {
      this.setState({ personNotFound: true });
    } else {
      const { personSlug } = this.props.match.params;
      const name = slugToName(personSlug);
      const currentPersonUserID = slugToId(personSlug);

      console.log("currentUser:: ", this.props.currentUser);
      const currentFriendUserData = this.props.currentUser.friends.items.find(
        (item) => item.user.personSlug === personSlug,
      );
      //console.log({ currentFriendUserData });
      this.setState({
        currentPerson: name,
        currentPersonSlug: personSlug,
        currentPersonUserID,
        currentUserFriendObj: currentFriendUserData,
        freeFromGiftIdeasList:
          currentFriendUserData &&
          currentFriendUserData.generalGiftIdeas &&
          currentFriendUserData.generalGiftIdeas,
      });
    }

    // Pull Information for friend given Slug
    // personSlug -> userDataKey -> userInfo?
    // New Table for user information.
    // id: personSlug
    loadQuestions()
      .then((questions) => {
        this.setState({ questions });
        questions.forEach((question) => {
          this.setState({ [question.id]: "" });
        });
        this.loadResponses()
          .then((res) => {
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
            this.setState({ loading: false });
          });
      })
      .catch((err) => console.log(err));
  }

  loadResponses = async () => {
    // Should the responses from the friend or the current user?
    const responsesByUserOrFriend =
      this.props.responsesByUserOrFriend || "self";
    let currentUser = await this.loadCurrentUser();
    if (!currentUser) return;
    let surveyTakenByID;
    if (responsesByUserOrFriend == "friend") {
      surveyTakenByID = this.state.currentPersonUserID;
    } else if (responsesByUserOrFriend == "self") {
      surveyTakenByID = currentUser.userDataKey; // same as cognitoUserDataKey
    } else {
      throw new Error(
        "Please specify loadResponses:: responsesByUserOrFriend. Should the survey results be from the friend or the user?",
      );
      return;
    }
    let answers = await this.loadAnswers(surveyTakenByID);
    this.setState({ answers });
    return answers;
  };

  loadCurrentUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const cognitoUserDataKey = user.userDataKey;
      this.setState({ cognitoUserInfo: user, cognitoUserDataKey });
      //console.log(cognitoUserDataKey, this.state.currentPersonUserID);
      return user;
    } catch (err) {
      console.log(err);
    }
  };

  loadAnswers = async (surveyTakenByID) => {
    const surveyResponse = await loadResponses(
      surveyTakenByID,
      this.state.currentPersonUserID,
    );
    //console.log("surveyRespons: ", surveyResponse)
    if (!surveyResponse) {
      this.setState({ loading: false });
      return [];
    }
    var answers = surveyResponse.answers;
    //console.log("Answers: ", answers)
    var giftIdeas = {};
    var numAnswersFound = 0;
    if (answers) {
      answers.items.forEach((answerObject) => {
        giftIdeas[answerObject.question.id] = answerObject.giftIdea
          ? answerObject.giftIdea
          : "";
        this.setState({
          [answerObject.question.id]: {
            id: answerObject.id,
            text: answerObject.response,
            updatedAt: answerObject.updatedAt,
          },
        });
      });
      numAnswersFound = answers.items.length;
    }
    //console.log("Answers:: ", answers);
    //Find the maximum of the UpdateDat Attribute.
    const latestUpdatedAt = answers.items.reduce((max, item) => {
      const itemDate = new Date(item.updatedAt);
      return itemDate > max ? itemDate : max;
    }, new Date(0));
    //console.log(latestUpdatedAt);
    this.setState({
      loading: false,
      giftIdeas,
      numAnswersFound,
      latestUpdatedAt: latestUpdatedAt,
    });
    return answers;
  };

  saveUserGiftIdeaFromState = (questionId) => {
    //console.log(this.state);
    this.setState({ savingResponses: true });
    const answer = this.state[questionId];
    if (answer && this.state.giftIdeas[questionId]) {
      // Return a promise to wait for the saving to be complete.
      return saveUserGiftIdea(answer.id, this.state.giftIdeas[questionId]);
    } else {
      console.log("Answer: ", answer);
      console.log("Gift Ideas: ", this.state.giftIdeas);
      this.setState({ savingResponses: false });
    }
  };

  saveFreeFormGiftIdeas = async () => {
    //console.log(this.state.currentUserFriendObj);
    if (!this.state.currentUserFriendObj) return;
    if (this.state.currentUserFriendObj.generalGiftIdeas) {
      const response = this.state.currentUserFriendObj.generalGiftIdeas.find(
        (giftIdea) => {
          return giftIdea.description === this.state.freeFromGiftIdeas;
        },
      );

      if (isAllowFreeFormGiftIdeas && !response) {
        await this.callSaveFreeFormGiftIdeasFunction();
      } else {
        toast.error("Already exist free form gift ideas!");
      }
    } else {
      if (isAllowFreeFormGiftIdeas) {
        await this.callSaveFreeFormGiftIdeasFunction();
      } else {
        toast.error("Already exist free form gift ideas!");
      }
    }
  };

  callSaveFreeFormGiftIdeasFunction = async () => {
    const res = await addGeneralGiftIdeaToUserFriend(
      this.state.freeFromGiftIdeas,
      this.state.currentUserFriendObj,
      this.props.currentUser.id,
    );
    if (res) {
      toast.success("Added free form gift ideas sucessfully!");
      this.setState({
        freeFromGiftIdeas: "",
        freeFromGiftIdeasList: res.data.updateUserFriend.generalGiftIdeas,
      });
    }
  };

  clickSubmit = async () => {
    //Save Response to each question.
    var savingPromises = this.state.questions
      .map((question) => {
        if (this.state[question.id]) {
          return this.saveUserGiftIdeaFromState(question.id);
        }
        return undefined;
      })
      .filter((x) => x !== undefined);
    Promise.all(savingPromises)
      .then((results) => {
        // results is an array containing the resolved values of each promise
        results.forEach((response) => console.log(response));
        this.setState({ savingResponses: false });
        this.props.navigate(
          `${
            this.props.responsesByUserOrFriend === "friend"
              ? "/friend-gift-ideas/"
              : "/gift-ideas/"
          }${this.state.currentPersonSlug}`,
          {
            state: {
              responsesByUserOrFriend:
                this.props.responsesByUserOrFriend || "self",
            },
          },
        );
      })
      .catch((error) => {
        toast.success("Something went wrong! Please try again later!");
        // Handle any errors here
        console.error("A promise in the array did not resolve", error);
      });
  };

  updateValue = (key, value) => {
    var { giftIdeas } = this.state;
    giftIdeas[key] = value;
    this.setState({ giftIdeas });
  };

  handleNextButton = () => {
    if (this.state.activeQuestion < this.state.questions.length - 1) {
      this.setState({ activeQuestion: this.state.activeQuestion + 1 });
    } else {
      this.props.navigate("/gift-ideas/" + this.state.currentPersonSlug);
    }
  };

  saveButton = (questionId) => {
    this.saveUserGiftIdeaFromState(questionId)
      .then((res) => {
        console.log(res);
        this.setState({ savingResponses: false });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ savingResponses: false });
      });
  };

  renderSubmitButton = () => {
    if (this.state.answers?.length == 0) {
      return <div />;
    }
    return this.state.savingResponses ? (
      <Spinner />
    ) : (
      <Button
        onClick={this.clickSubmit}
        color="primary"
        data-cy="submit-button"
      >
        Next
      </Button>
    );
  };

  renderBackButton = () => {
    if (this.state.activeQuestion > 0) {
      return (
        <Button
          color="secondary"
          onClick={() => {
            this.setState({ activeQuestion: this.state.activeQuestion - 1 });
          }}
        >
          Back
        </Button>
      );
    } else {
      return (
        <Button color="secondary" onClick={() => this.props.navigate(-1)}>
          Back
        </Button>
      );
    }
  };

  // TODO: Modify to only display question if there is an answer to it.
  renderQuestionList = () => {
    //console.log("State: ", this.state)
    if (!this.state.loading && this.state.answers?.length == 0) {
      return <h4>No answers received yet. Please check back again later.</h4>;
    }

    return this.state.questions.map((question, index) => {
      if (!this.state[question.id]) {
        return "";
      }
      return (
        <Row key={question.id}>
          <Col sm="12" md={{ size: 7 }}>
            <div>
              <H6>{question.text}</H6>
              {this.state.loading ? (
                <Spinner size="sm" />
              ) : this.state[question.id] ? (
                this.state[question.id].text
              ) : (
                " "
              )}
            </div>
          </Col>
          <Col sm="12" md={{ size: 4 }}>
            <Form>
              <FormGroup>
                <Label for={question.id}>Do any gift ideas come to mind?</Label>
                <Input
                  type="textarea"
                  name={question.id}
                  placeholder="Enter gift idea..."
                  value={this.state.giftIdeas[question.id]}
                  onChange={(event) =>
                    this.updateValue(question.id, event.target.value)
                  }
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
      );
    });
  };

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    if (this.state.personNotFound) {
      return (
        <div>
          <H1>Person Not Found!</H1>
          <H3>Please refresh page or confirm the url has the persons name.</H3>
        </div>
      );
    }
    return (
      <UserContext.Consumer>
        {({ currentUser, updateUser }) => (
          <Container>
            <H1 style={{ textAlign: "center", padding: 30 }}>
              What do you think{" "}
              {this.state.currentPerson
                ? this.state.currentPerson
                : "your friend"}{" "}
              wants?
            </H1>
            {this.state.welcomeMessage}
            <p />
            <p />
            <Row>
              <Col sm="12" md={{ size: 10, offset: 1 }}>
                {this.renderQuestionList()}
              </Col>
              {isAllowFreeFormGiftIdeas && (
                <Col sm="12" md={{ size: 8, offset: 2 }}>
                  <Row>
                    <div>
                      <H6>
                        Do you have any other gift ideas not which don’t connect
                        directly to one of the questions above?
                      </H6>
                    </div>
                  </Row>
                  <Row className="mb-4">
                    {this.state.freeFromGiftIdeasList &&
                      this.state.freeFromGiftIdeasList.map((gift, index) => {
                        return (
                          <Col sm="12" md={{ size: 8, offset: 2 }} key={index}>
                            <div>{gift.description}</div>
                          </Col>
                        );
                      })}
                  </Row>
                  <Row>
                    <Col sm="12" md={{ size: 8, offset: 2 }}>
                      <Form>
                        <FormGroup className="d-flex justify-content-center align-items-center gap-2">
                          <Input
                            type="textarea"
                            data-cy="freeFormGiftIdeas"
                            name="freeFromGiftIdeas"
                            placeholder="Enter additional gift ideas here..."
                            value={this.state.freeFromGiftIdeas}
                            onChange={(event) =>
                              this.setState({
                                freeFromGiftIdeas: event.target.value,
                              })
                            }
                          />
                          <FontAwesomeIcon
                            icon={faPlus}
                            color="#33d3ea"
                            size="lg"
                            data-cy="add-freeform-giftideas-plus-icon"
                            onClick={() => this.saveFreeFormGiftIdeas()}
                          />
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            <Row>
              <Col
                sm="12"
                md={{ size: 3, offset: 8 }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "4px",
                }}
              >
                {this.renderBackButton()}
                {"  "}
                {this.renderSubmitButton()}
              </Col>
            </Row>
            {this.state.answers?.length > 0 ? (
              <Row>
                <Col sm="12" md={{ size: 4, offset: 4 }}>
                  Nothing coming to mind?{" "}
                  <Link to={"/getting-to-know/" + this.state.currentPersonSlug}>
                    <Button marginLeft={10}>Edit their profile</Button>
                  </Link>
                </Col>
              </Row>
            ) : (
              ""
            )}
            <p />
            {this.state.latestUpdatedAt ? (
              <div>
                Response Received at {this.state.latestUpdatedAt.toString()}
              </div>
            ) : (
              ""
            )}
          </Container>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withUserContext(withRouter(UserGiftIdeas));
