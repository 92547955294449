//Added the friends to three layers deep?

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      personSlug
      firstName
      lastName
      email
      phone
      addressStreet1
      addressStreet2
      addressCity
      addressState
      addressZip
      addressCountry
      birthday
      annualBudget
      createdAt
      updatedAt
      description
      viewers
      owner
      friends {
        items {
          id
          lastGiftPurchasedDateTime
          generalGiftIdeas {
            description
            active
            createdAt
            createdByID
          }
          relation
          selected
          user {
            email
            firstName
            lastName
            id
            personSlug
            birthday
            profileImageUrl
          }
        }
        nextToken
        __typename
      }
      daysBetweenReminders
      maxDaysBeforeEventToRemind
      sendEmailBirthdayReminders
      subscriptionPlan
      subscriptionStatus
      subscriptionStartedAt
      paymentGatewayId
      profileImageUrl
      surveysAboutUser {
        nextToken
        __typename
      }
      surveysTaken {
        nextToken
        __typename
      }
      __typename
    }
  }
`;

export const listSurveyResponses = /* GraphQL */ `
  query ListSurveyResponses(
    $filter: ModelSurveyResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSurveyResponses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        description
        takenByUserID
        aboutUserID
        createdAt
        updatedAt
        answers {
          items {
            response
            aiGiftIdea
            giftIdea
            question {
              text
              id
            }
            updatedAt
            id
          }
        }
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const getUserFriend = /* GraphQL */ `
  query GetUserFriend($id: ID!) {
    getUserFriend(id: $id) {
      id
      primaryUserID
      user {
        id
        personSlug
        firstName
        lastName
        email
        phone
        addressStreet1
        addressStreet2
        addressCity
        addressState
        addressZip
        addressCountry
        birthday
        annualBudget
        createdAt
        updatedAt
        description
        daysBetweenReminders
        maxDaysBeforeEventToRemind
        sendEmailBirthdayReminders
        subscriptionPlan
        subscriptionStatus
        subscriptionStartedAt
        paymentGatewayId
        profileImageUrl
        __typename
      }
      relation
      selected
      lastGiftPurchasedDateTime
      giftRecommendations {
        items {
          id
          viewed
          purchasedOn
          active
          product {
            externalId
            externalUrl
            description
            productImageUrl
            price
            rating
            title
            id
          }
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const createSurveyResponseLimitedAccess = `
mutation CreateSurveyResponse(
  $input: CreateSurveyResponseInput!
  $condition: ModelSurveyResponseConditionInput
) {
  createSurveyResponse(input: $input, condition: $condition) {
    id
    title
    description
    takenByUserID
    aboutUserID
    createdAt
    updatedAt
    __typename
  }
}
`;

export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userFriendID
        qna {
          question
          answer
          __typename
        }
        message
        aiMessage
        scheduledToSendOn
        messageSentOn
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listGiftRecommendations = /* GraphQL */ `
  query ListGiftRecommendations(
    $filter: ModelGiftRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGiftRecommendations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userFriendID
        viewed
        purchasedOn
        product {
          id
          __typename
        }
        active
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
  }
`;
