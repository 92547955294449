import awsmobile from "./aws-exports";

const { aws_appsync_graphqlEndpoint } = awsmobile;

// TODO: Make this dynamic so the code doesn't need to change to change the endpoints. Move it to the config file as well.
//    Note: We shouldn't need to deploy to development in order to update the staging api endpoint name.
//   Move this to load from the database.

function determineStack(graphql_api_url) {
  switch (
    graphql_api_url // Convert input to lower case for case-insensitive matching
  ) {
    // Development
    case "https://4wagagpdpjaapmpyofazsxx6dm.appsync-api.us-east-1.amazonaws.com/graphql":
      return "dev"; // Note a slash is needed on the end of the URL
    // Staging (test)
    case "https://wceakkpgbzfyzejjibdjwlbbjq.appsync-api.us-east-1.amazonaws.com/graphql":
      return "staging";
    // Production
    case "https://2mcracmbrrhxzf642qfvuzebp4.appsync-api.us-east-1.amazonaws.com/graphql":
      return "production";
    default:
      throw new Error(
        "Graphql Appsync URL was not found. Please update constants/index.js",
      );
      return "No match found";
  }
}

const STAGE = determineStack(aws_appsync_graphqlEndpoint);

const API_ENDPOINTS = {
  dev: {
    NOTIFY_SENDER_API_URL:
      "https://cbhlrb3560.execute-api.us-east-1.amazonaws.com/",
    GIFT_IDEA_API_URL:
      "https://yn0s9wb0c8.execute-api.us-east-1.amazonaws.com/",
    PRODUCT_INFO_API_URL:
      "https://19kw1d6so2.execute-api.us-east-1.amazonaws.com/",
  },
  staging: {
    NOTIFY_SENDER_API_URL:
      "https://so9v20rumg.execute-api.us-east-1.amazonaws.com/",
    GIFT_IDEA_API_URL:
      "https://mas7ni2zvj.execute-api.us-east-1.amazonaws.com/",
    PRODUCT_INFO_API_URL:
      "https://urcldw4eg5.execute-api.us-east-1.amazonaws.com/",
  },
  production: {
    NOTIFY_SENDER_API_URL:
      "https://2gwei6cl5j.execute-api.us-east-1.amazonaws.com/",
    GIFT_IDEA_API_URL:
      "https://bhakp6f5n8.execute-api.us-east-1.amazonaws.com/",
    PRODUCT_INFO_API_URL:
      "https://qn6t15wn9h.execute-api.us-east-1.amazonaws.com/",
  },
};

export const NOTIFY_SENDER_API_URL = API_ENDPOINTS[STAGE].NOTIFY_SENDER_API_URL;
export const GIFT_IDEA_API_URL = API_ENDPOINTS[STAGE].GIFT_IDEA_API_URL;
export const PRODUCT_INFO_API_URL = API_ENDPOINTS[STAGE].PRODUCT_INFO_API_URL;
