import { API, graphqlOperation } from "aws-amplify";
import { updateUserFriend } from "../graphql/mutations";

export const addGeneralGiftIdeaToUserFriend = async (
  generalGiftIdeaText,
  userFriendObject,
  primaryUserID,
) => {
  // Add the generalGiftIdeaText to the userFriendObject.generalGiftIdeas array (if it exists or create a new array if it doesn't)
  // Save the updated userFriend object with the new generalGiftIdeas to the database.
  const generalGiftIdea = {
    description: generalGiftIdeaText,
    active: true,
    createdAt: new Date(),
    createdByID: primaryUserID,
  };

  if (userFriendObject.generalGiftIdeas) {
    userFriendObject.generalGiftIdeas.push(generalGiftIdea);
  } else {
    userFriendObject.generalGiftIdeas = [generalGiftIdea];
  }
  const userFriendDetail = {
    id: userFriendObject.id,
    generalGiftIdeas: userFriendObject.generalGiftIdeas,
  };
  const res = await updateUserFriendObj(userFriendDetail);
  return res;
};

export const updateUserFriendObj = async (userFriendDetail) => {
  try {
    const response = await API.graphql(
      graphqlOperation(updateUserFriend, { input: userFriendDetail }),
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
