import React, { Fragment } from "react";
// import { Container, Row, Col, Button } from 'reactstrap'
// import { useNavigate, useParams } from 'react-router';
// import { useSearchParams } from 'react-router-dom';
// import { H1, H3 } from '../components/elements';
import UserGiftIdeas from "./UserGiftIdeas";

const welcomeMessage =
  "Review what your friend has shared about themself and see what gift ideas you can come up with.";

const ViewSurveyResponse = () => {
  return (
    <UserGiftIdeas
      isAllowFreeFormGiftIdeas={false}
      responsesByUserOrFriend="friend"
      welcomeMessage={welcomeMessage}
    />
  );
};

export default ViewSurveyResponse;
