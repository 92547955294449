import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  Row,
  Col,
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { H5 } from "./elements";

const AddGiftRecommendation = ({
  isOpen,
  onClose,
  onSubmit,
  giftRecommendation,
  updateValue,
  savingData,
  productExternalUrl,
  products,
}) => {
  const [searchProduct, setSearchProduct] = useState("");
  const [filterProductLists, setFilterProductLists] = useState(products);

  useEffect(() => {
    setFilterProductLists(products);
  }, []);

  useEffect(() => {
    if (searchProduct) {
      const filteredProducts = products.filter((product) =>
        product.title.toLowerCase().includes(searchProduct.toLowerCase()),
      );
      setFilterProductLists(filteredProducts);
    } else setFilterProductLists(products);
  }, [searchProduct]);

  const giftIdeaFields = {
    giftRecommendationProductId: {
      label: "Product Id",
      type: "text",
      name: "giftRecommendationProductId",
      id: "giftRecommendationProductId",
      placeholder: "ANOIK120VNAK20M",
    },
    productExternalUrl: {
      label: "Product Webpage",
      type: "text",
      name: "productExternalUrl",
      id: "productExternalUrl",
      placeholder: "https://www.amazon.com/ANOIK120VNAK20M",
    },
    searchProduct: {
      label: "Search product",
      type: "text",
      name: "searchProduct",
      id: "searchProduct",
      placeholder: "Product name...",
    },
  };

  const handleKeyDown = (e) => {
    console.log(e.keyCode1);
    if (e.keyCode === 8) {
      setSearchProduct("");
    }
  };

  const renderInputField = (inputParams, productExternalUrl, updateValue) => {
    return (
      <div>
        <Label for={inputParams.name}>{inputParams.label}</Label>
        <Input
          type={inputParams.type}
          name={inputParams.name}
          id={inputParams.id}
          placeholder={inputParams.placeholder}
          value={productExternalUrl || ""}
          onChange={(event) =>
            updateValue("giftRecommendation", {
              ...giftRecommendation,
              [inputParams.name]: event.target.value,
            })
          }
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  };

  const renderProducts = useCallback(
    (onSubmit, updateValue, giftRecommendation) => {
      var productsDisplay = filterProductLists;

      if (filterProductLists.length === 0) {
        return <p>No matching products found.</p>;
      }

      if (filterProductLists.length > 20) {
        productsDisplay = filterProductLists.slice(0, 20);
      }
      return (
        <ul>
          {productsDisplay.map((product) => {
            return (
              product.title && (
                <div key={product.id}>
                  <Row className="d-flex align-items-center gap-2">
                    <Col lg={{ size: 1 }}>
                      <Button
                        onClick={() => {
                          console.log(
                            "inside btn clicked : ",
                            product.id,
                            giftRecommendation,
                          );
                          updateValue("giftRecommendation", {
                            ...giftRecommendation,
                            giftRecommendationProductId: product.id,
                          });
                          onSubmit();
                        }}
                        data-cy="add-product-select-from-list-button"
                      >
                        +
                      </Button>
                    </Col>
                    <Col>{product.title}</Col>
                  </Row>
                  <p />
                </div>
              )
            );
          })}
        </ul>
      );
    },
    [searchProduct, filterProductLists],
  );

  return (
    <Fragment>
      <Modal isOpen={isOpen} toggle={onClose}>
        <ModalHeader toggle={onClose}>New Gift idea?</ModalHeader>
        <ModalBody>
          <Form onSubmit={onSubmit}>
            <FormGroup>
              {renderInputField(
                giftIdeaFields["productExternalUrl"],
                productExternalUrl,
                updateValue,
              )}
            </FormGroup>
          </Form>
          <div className="d-flex justify-content-end gap-1">
            <Button color="secondary" onClick={onClose} data-cy="cancel-button">
              Cancel
            </Button>
            {savingData ? (
              <Spinner />
            ) : (
              <div>
                <Button
                  color="primary"
                  onClick={() => {
                    onSubmit(true);
                  }}
                  data-cy="next-button"
                >
                  Next
                </Button>{" "}
                <Button
                  color="secondary"
                  onClick={() => {
                    onSubmit(false);
                  }}
                  data-cy="done-button"
                >
                  Done
                </Button>
              </div>
            )}
          </div>
          <hr />
          <H5 className="text-left">Select Products to Recommend</H5>
          <Input
            type="text"
            name="searchProduct"
            id="searchProduct"
            placeholder="Search product here..."
            value={searchProduct}
            onChange={(e) => setSearchProduct(e.target.value)}
            className="mb-3"
            onKeyDown={(e) => setSearchProduct(e.target.value)}
          />
          {renderProducts(onSubmit, updateValue, giftRecommendation)}
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

export default AddGiftRecommendation;
