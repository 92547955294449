import React, { Fragment } from "react";
import { Auth } from "aws-amplify";

const signOut = () => {
  console.log("Signing Out");
  Auth.signOut()
    .then(() => {
      this.props.history.push("/");
    })
    .catch((err) => console.log("error signing out...", err));
};

class SignOutPage extends React.Component {
  componentDidMount() {
    signOut();
  }

  render() {
    return (
      <Fragment>
        <h1 style={{ marginTop: 30 }}>Signing Out...</h1>
      </Fragment>
    );
  }
}

export default SignOutPage;
