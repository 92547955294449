import React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
} from "reactstrap";
import { H1, H4 } from "../components/elements";

class WishlistRequest extends React.Component {
  state = {
    friendEmail: "",
    friendAddress: "",
    birthday: "",
    currentPersonSlug: "",
  };

  componentDidMount() {
    if (!this.props.match) {
      this.setState({ personNotFound: true });
    } else {
      const { personSlug } = this.props.match.params;
      this.setState({ currentPersonSlug: personSlug });
    }
  }

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    return (
      <Container>
        <H1 style={{ textAlign: "center", padding: 30 }}>Ask them?</H1>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <H4>Would you like to ask your friend what they would like?</H4>
            Asking someone want they want for their birthday can go a long way.
            If you had a hard time answering some of the prior questions, it
            might be easier just to ask.
            <p />
          </Col>
        </Row>
        <Row>
          <Col sm="12" md={{ size: 3, offset: 4 }}>
            <Form>
              <FormGroup>
                <Label for="friendEmail">Email</Label>
                <Input
                  type="email"
                  name="friendEmail"
                  id="friendEmail1"
                  placeholder="sam@gmail.com"
                  value={this.state.friendEmail}
                  onChange={(event) =>
                    this.updateValue("friendEmail", event.target.value)
                  }
                />
                <Label for="birthday">Birthday</Label>
                <Input
                  type="text"
                  name="birthday"
                  id="birthday"
                  placeholder="3/1/1996"
                  value={this.state.birthday}
                  onChange={(event) =>
                    this.updateValue("birthday", event.target.value)
                  }
                />
                <Label for="friendAddress">Address</Label>
                <Input
                  type="text"
                  name="friendAddress"
                  id="friendAddress"
                  placeholder="1234 Joy St, Happyville, CO, 80026"
                  value={this.state.friendAddress}
                  onChange={(event) =>
                    this.updateValue("friendAddress", event.target.value)
                  }
                />
              </FormGroup>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col sm="12" md={{ size: 2, offset: 8 }}>
            <Link to={"/gift-ideas/" + this.state.currentPersonSlug}>
              <Button color="primary">Next</Button>
            </Link>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default WishlistRequest;
