import { useEffect, useMemo, useRef, useState } from "react";
import { Container, Form, FormGroup, Input, Row, Col } from "reactstrap";
import CustomSwitch from "../common/CustomSwitchInput";
import { H4 } from "../components/elements";
import { withUserContext } from "../UserContext";
import { updateUserInDb } from "../services/user";
import { toast } from "react-toastify";
import CustomLoadingSpinner from "../components/CustomLoadingSpinner";

const Settings = ({ currentUser, updateUser }) => {
  const [settingsValue, setSettingsValue] = useState({
    id: currentUser.id,
    daysBetweenReminders: currentUser.daysBetweenReminders || 7,
    maxDaysBeforeEventToRemind: currentUser.maxDaysBeforeEventToRemind || 30,
    sendEmailBirthdayReminders: currentUser.sendEmailBirthdayReminders || false,
  });
  const [timeoutId, setTimeoutId] = useState(null);
  const [loading, setLoading] = useState(false);
  const isFirstMount = useRef(true);

  const settingFields = useMemo(
    () => [
      {
        type: "switch",
        id: "sendEmailBirthdayReminders",
        name: "sendEmailBirthdayReminders",
        label: "Send Email Reminder",
      },
      {
        type: "number",
        id: "daysBetweenReminders",
        name: "daysBetweenReminders",
        label: "How many days between reminders should their be?",
      },
      {
        type: "number",
        id: "maxDaysBeforeEventToRemind",
        name: "maxDaysBeforeEventToRemind",
        label:
          "How many days before someone's birthday should we send out a reminder?",
      },
    ],
    [settingsValue],
  );

  const renderInput = (setting) => {
    switch (setting.type) {
      case "text":
      case "number":
        return (
          <Input
            type={setting.type}
            name={setting.name}
            id={setting.id}
            placeholder={setting?.placeholder}
            value={settingsValue[setting.id]}
            onChange={(e) => handleInputChange(setting.name, e.target.value)}
          />
        );
      case "switch":
        return (
          <Form>
            <FormGroup>
              <CustomSwitch
                id="exampleSwitch"
                label=""
                checked={settingsValue[setting.id]}
                onChange={() => handleSwitchChange(setting.id)}
              />
            </FormGroup>
          </Form>
        );
      default:
        <Input
          type={setting.type}
          name={setting.name}
          id={setting.id}
          placeholder={setting?.placeholder}
          value={settingsValue[setting.id]}
          onChange={(e) => handleInputChange(setting.name, e.target.value)}
        />;
    }
  };

  const handleInputChange = (key, value) => {
    setSettingsValue((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
    updateUser(key, value);
  };

  const handleSwitchChange = (key) => {
    setSettingsValue((prevSettings) => ({
      ...prevSettings,
      [key]: !prevSettings[key],
    }));
    updateUser(key, !settingsValue.sendEmailBirthdayReminders);
  };

  useEffect(() => {
    if (isFirstMount.current) {
      isFirstMount.current = false;
      return;
    }
    clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(async () => {
      setLoading(true);
      try {
        await updateUserInDb(settingsValue);
        toast.success("Updated successfully!");
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.success("Please try again after sometime!");
        console.error(err);
      }
    }, 1500);

    setTimeoutId(newTimeoutId);
  }, [settingsValue]);

  return (
    <Container className="setting-wrapper">
      <H4 className="mb-5">Settings</H4>
      {settingFields.map((setting, index) => {
        return (
          <div key={index} className="setting-container">
            <div className="setting-label">{setting.label}</div>
            <div>{renderInput(setting)}</div>
          </div>
        );
      })}
    </Container>
  );
};

export default withUserContext(Settings);
