import { Fragment, useEffect, useRef, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { Div, Image, Ul, Li, Button, FontAwesome } from "../elements";
import { Link, NavLink } from "react-router-dom";
import Sticky from "react-sticky-el";
import { faChevronDown, faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./style.scss";

const HeaderMenu = ({
  isLoaded,
  isAuthenticated,
  setResponsiveMenu,
  signOut,
  setIsOpenProfileMenu,
  isOpenProfileMenu,
  profileMenuRef,
  isMobile = false,
}) => {
  const handleProfileClick = () => {
    setIsOpenProfileMenu(!isOpenProfileMenu);
  };

  return (
    <Ul className="mainMenu">
      <Li key="home">
        <Link to="/" onClick={() => setResponsiveMenu(false)}>
          HOME
        </Link>
      </Li>
      <Li key="pricing">
        <Link to="/pricing" onClick={() => setResponsiveMenu(false)}>
          PRICING
        </Link>
      </Li>
      <Li key="about">
        <Link to="/about" onClick={() => setResponsiveMenu(false)}>
          ABOUT
        </Link>
      </Li>
      {isLoaded && isAuthenticated && (
        <Fragment>
          <Li key="Friends">
            <Link
              to="/upcoming-birthdays"
              onClick={() => setResponsiveMenu(false)}
            >
              FRIENDS
            </Link>
          </Li>
          <Li key="Profile">
            <div className="profile-link" onClick={handleProfileClick}>
              <div onClick={handleProfileClick}>Account</div>
              <FontAwesomeIcon icon={faChevronDown} size="lg" color="#FFFFFF" />
              {isOpenProfileMenu && (
                <div className="profile-menu-card" ref={profileMenuRef}>
                  <ul>
                    <Li key="Profile">
                      <Link
                        to="/profile"
                        onClick={() => setResponsiveMenu(false)}
                      >
                        Profile
                      </Link>
                    </Li>
                    <Li key="Budget">
                      <Link
                        to="/budget"
                        onClick={() => setResponsiveMenu(false)}
                      >
                        Budget
                      </Link>
                    </Li>
                    <Li key="Settings">
                      <Link
                        to="/settings"
                        onClick={() => setResponsiveMenu(false)}
                      >
                        Settings
                      </Link>
                    </Li>
                  </ul>
                </div>
              )}
            </div>
          </Li>
        </Fragment>
      )}
      <Li key="auth">
        {isLoaded ? (
          isAuthenticated ? (
            <Link
              to="/"
              onClick={() => {
                setResponsiveMenu(false);
                signOut();
              }}
              data-cy={!isMobile && "sign-out-button"}
            >
              SIGN OUT
            </Link>
          ) : (
            <Link
              to="/login"
              onClick={() => setResponsiveMenu(false)}
              data-cy="sign-in-button"
            >
              SIGN IN
            </Link>
          )
        ) : null}
      </Li>
    </Ul>
  );
};

const HeaderComponent = ({
  className,
  button,
  fontawesome,
  logo,
  id,
  isLoaded,
  isAuthenticated,
  signOut,
}) => {
  const [responsiveMenu, setResponsiveMenu] = useState(false);
  const [isOpenProfileMenu, setIsOpenProfileMenu] = useState(false);
  const profileMenuRef = useRef(null);

  const handleClickOutside = (event) => {
    if (
      profileMenuRef.current &&
      !profileMenuRef.current.contains(event.target) &&
      !event.target.closest(".profile-link")
    ) {
      setIsOpenProfileMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const mobileHeader = (
    <Col
      lg={button ? 7 : 9}
      className={`d-block d-lg-none ${
        responsiveMenu ? "responsiveMenuWrap active" : "responsiveMenuWrap"
      }`}
    >
      <HeaderMenu
        isLoaded={isLoaded}
        isAuthenticated={isAuthenticated}
        setResponsiveMenu={setResponsiveMenu}
        signOut={signOut}
        setIsOpenProfileMenu={setIsOpenProfileMenu}
        isOpenProfileMenu={isOpenProfileMenu}
        profileMenuRef={profileMenuRef}
        isMobile={true}
      />
    </Col>
  );

  const desktopHeader = (
    <Col lg={button ? 7 : 9} className={`d-none d-lg-block`}>
      <HeaderMenu
        isLoaded={isLoaded}
        isAuthenticated={isAuthenticated}
        setResponsiveMenu={setResponsiveMenu}
        signOut={signOut}
        setIsOpenProfileMenu={setIsOpenProfileMenu}
        isOpenProfileMenu={isOpenProfileMenu}
        profileMenuRef={profileMenuRef}
      />
    </Col>
  );

  return (
    <div style={{ marginBottom: 100, padding: 10 }}>
      <Sticky className={`header headerArea ${className}`} id={id}>
        <Container>
          <Row className="header-container">
            <Col lg={3} sm={button ? 7 : 5} xs={6}>
              <Div className="logo">
                <NavLink to="/">
                  <Image src={logo} alt="logo" />
                </NavLink>
              </Div>
            </Col>
            {desktopHeader}
            {mobileHeader}
            {isOpenProfileMenu && responsiveMenu && (
              <div className="profile-menu-card" ref={profileMenuRef}>
                <ul>
                  <Li
                    key="Profile"
                    onClick={() => {
                      setIsOpenProfileMenu(!isOpenProfileMenu);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faAngleLeft}
                      size="lg"
                      className="menu-icon"
                    />
                    &nbsp;&nbsp;All
                  </Li>
                  <Li key="Profile">
                    <Link
                      to="/profile"
                      onClick={() => {
                        setResponsiveMenu(false);
                        setIsOpenProfileMenu(false);
                      }}
                    >
                      Profile
                    </Link>
                  </Li>
                  <Li key="Budget">
                    <Link
                      to="/budget"
                      onClick={() => {
                        setResponsiveMenu(false);
                        setIsOpenProfileMenu(false);
                      }}
                    >
                      Budget
                    </Link>
                  </Li>
                  <Li key="Settings">
                    <Link
                      to="/settings"
                      onClick={() => {
                        setResponsiveMenu(false);
                        setIsOpenProfileMenu(false);
                      }}
                    >
                      Settings
                    </Link>
                  </Li>
                </ul>
              </div>
            )}
            {button && (
              <Col lg={2} sm={4} className="d-none d-sm-block">
                <Button className="fileDownloadBtn">
                  {button}
                  {fontawesome && <FontAwesome name={fontawesome} />}
                </Button>
              </Col>
            )}
            <Col className="d-lg-none d-block" sm={7} xs={6}>
              <Ul
                onClick={() => setResponsiveMenu(!responsiveMenu)}
                className={`responsiveMenuTigger ${
                  responsiveMenu ? "active" : ""
                }`}
              >
                <Li className="first"></Li>
                <Li className="second"></Li>
                <Li className="third"></Li>
              </Ul>
            </Col>
          </Row>
        </Container>
      </Sticky>
    </div>
  );
};
export default HeaderComponent;
