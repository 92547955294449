import axios from "axios";
import { PRODUCT_INFO_API_URL } from "../config";
import { getCurrentUserToken } from "../containers/auth/verifyAccessToken.mjs";
//import { extractExternalIdFromUrl } from "../utils";

export async function getExternalProductdata(externalId, productId) {
  // The URL of the FastAPI app on the local development server

  //const externalId = getExternalIdFromUrl(externalUrl);

  const url = `${PRODUCT_INFO_API_URL}${externalId}?productId=${productId}`;
  console.log("Calling: ", url);

  const token = await getCurrentUserToken();
  // Sending a POST request to the API endpoint
  const config = {
    headers: {
      Authorization: token,
      "Content-Type": "application/json",
    },
  };

  try {
    // Sending a POST request to the API endpoint using Axios
    const response = await axios.post(url, {}, config);

    // Handling the success response
    console.log("Success! Received response from the server:");
    console.log(response.data); // Printing the data received from the server
    return response;
  } catch (error) {
    // Error handling
    console.error(error);
    //console.error(`Error. Status code: ${error.response.status}`);
    //console.error(error.response.data);
    return;
  }
}
