import { API, graphqlOperation } from "aws-amplify";
import { createMessage, updateMessage } from "../graphql/mutations";
import { listMessages } from "../graphql/queries_manual";

export const addHBDMessage = async (messageDetail) => {
  /*
  CreateMessageInput {
    id: ID
    userFriendID: ID!
    qna: [QuestionAndAnswerInput]
    message: String
    aiMessage: String
    messagedSentOn: AWSDateTime
  }
*/

  try {
    const response = await API.graphql(
      graphqlOperation(createMessage, { input: messageDetail }),
    );
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const updateHBDMessage = async (messageDetail) => {
  const updateMessageInput = {
    id: messageDetail.id,
    userFriendID: messageDetail.userFriendID,
    message: messageDetail.message,
    qna: messageDetail.qna,
    scheduledToSendOn: messageDetail.scheduledToSendOn,
  };

  try {
    let res = await API.graphql(
      graphqlOperation(updateMessage, { input: updateMessageInput }),
    );
    return res.data.updateMessage;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const getHBDMessage = async (id) => {
  const allResponses = await getAllMessage(id);
  console.log("response all msg : ", allResponses);
  if (allResponses.length > 0) {
    const sortedMessages = allResponses.sort(
      (a, b) => new Date(b.scheduledToSendOn) - new Date(a.scheduledToSendOn),
    );
    return sortedMessages[0];
  } else {
    return null;
  }
};

async function getAllMessage(id) {
  let allResponses = [];
  try {
    let nextToken = null;
    do {
      let response = await fetchMessages(id, nextToken);
      if (response.data.listMessages.items.length > 0) {
        allResponses = allResponses.concat(response.data.listMessages.items);
      }
      nextToken = response.data.listMessages.nextToken;
    } while (nextToken);

    return allResponses;
  } catch (error) {
    console.error(error);
    return [];
  }
}

async function fetchMessages(id, nextToken) {
  const filter = {
    userFriendID: { eq: id },
  };
  try {
    let response = await API.graphql({
      query: listMessages,
      variables: { filter, limit: 1000, nextToken },
    });
    //console.log(response.data.listMessages, "response");
    return response;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
