import { API, Auth } from "aws-amplify";
import axios from "axios";
import { NOTIFY_SENDER_API_URL } from "../config";

// TODO: Modify to use the API_KEY to verify request.

export async function getNotifySender(sendersUserID, surveyID) {
  //const token = await getCurrentUserToken(); // Only valid if the user is logged in.
  const requestData = {
    // headers: {
    //   Authorization: token,
    // },

    //queryStringParameters for API.get
    params: {
      sendersUserID,
      surveyID,
    },
  };
  console.log("Notifying sender...");
  try {
    //const response = await API.get("NotifySender", "", requestData);
    const response = await axios.get(NOTIFY_SENDER_API_URL, requestData);
    console.log("GET call succeeded: ", response);
    return response;
  } catch (error) {
    console.log("GET call failed: ", error);
    return error;
  }
}
