import React from "react";
import moment from "moment";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Form,
  Label,
  Spinner,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { H1, H4 } from "../components/elements";
import { withRouter } from "../legacy/react-router-old";
import { withUserContext } from "../UserContext";
import {
  addNewUserIDAndSlug,
  cleanAndSaveNewUser,
  updateUserInDb,
} from "../services/user";
import { toast } from "react-toastify";

const UserForm = ({
  currentUser,
  updateUser,
  termsAccepted,
  handleTermsChange,
}) => {
  const formFields = [
    {
      name: "email",
      type: "email",
      placeholder: "sam@gmail.com",
      value: currentUser.email,
    },
    {
      name: "firstName",
      type: "text",
      placeholder: "Sam",
      value: currentUser.firstName,
    },
    {
      name: "lastName",
      type: "text",
      placeholder: "Johnson",
      value: currentUser.lastName,
    },
    {
      name: "birthdayReadable",
      label: "Birthday",
      type: "date",
      placeholder: "MM/DD/YYYY",
      value: currentUser.birthdayReadable,
    },
    {
      name: "addressStreet1",
      type: "text",
      placeholder: "1234 Joy St",
      value: currentUser.addressStreet1,
    },
    {
      name: "addressStreet2",
      type: "text",
      placeholder: "Unit C",
      value: currentUser.addressStreet2,
    },
    {
      name: "addressCity",
      type: "text",
      placeholder: "Happyville",
      value: currentUser.addressCity,
    },
    {
      name: "addressState",
      type: "text",
      placeholder: "Colorado",
      value: currentUser.addressState,
    },
    {
      name: "addressZip",
      type: "text",
      placeholder: "80027",
      value: currentUser.addressZip,
    },
  ];

  return (
    <Form>
      <FormGroup>
        {formFields.map((field) => (
          <React.Fragment key={field.name}>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Label for={field.name}>
                {field.label ||
                  field.name.charAt(0).toUpperCase() +
                    field.name.slice(1).replace(/([A-Z])/g, " $1")}
              </Label>
              {field.type === "date" ? (
                <DatePicker
                  selected={
                    field.value && field.value !== "Invalid date"
                      ? moment(field.value, "MM/DD/YYYY").toDate()
                      : null
                  }
                  onChange={(date) =>
                    updateUser(
                      field.name,
                      date === null ? "Invalid date" : date,
                    )
                  }
                  placeholderText={field.placeholder}
                  name={field.name}
                  id={field.name}
                  dateFormat="MM/dd/yyyy"
                  className="form-control"
                />
              ) : (
                <Input
                  type={field.type}
                  name={field.name}
                  id={field.name}
                  placeholder={field.placeholder}
                  value={field.value || ""}
                  onChange={(event) =>
                    updateUser(field.name, event.target.value)
                  }
                />
              )}
            </div>
          </React.Fragment>
        ))}
        <Label
          check
          style={{
            marginTop: "20px",
          }}
        >
          <Input
            type="checkbox"
            name="termsAccepted"
            id="termsAccepted"
            checked={termsAccepted}
            onChange={handleTermsChange}
          />
          &nbsp; I accept the{" "}
          <a
            href="https://www.generous-giver.com/terms-of-service"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </a>
        </Label>
      </FormGroup>
    </Form>
  );
};

class Profile extends React.Component {
  state = {
    savingUser: false,
    personNotFound: false,
    termsAccepted: false,
  };

  async componentDidMount() {
    if (
      !this.props.match.length &&
      Object.keys(this.props.currentUser).length < 3
    ) {
      const createUserInput = {
        id: this.props.currentUser.id,
        email: this.props.currentUser.email,
        firstName: this.props.currentUser.email.split("@")[0],
        lastName: this.props.currentUser.email.split("@")[0],
      };
      await cleanAndSaveNewUser(createUserInput);
      this.setState({ personNotFound: true });
    } else {
      const { personSlug } = this.props.match.params;
      this.setState({ currentPersonSlug: personSlug });
    }
  }

  selectOnlyProfileFields = (userData) => {
    // Select only the fields that changed.
    //console.log(userData);
    return {
      id: userData.id,
      email: userData.email, // Email
      firstName: userData.firstName, // First Name
      lastName: userData.lastName, // Last Name
      birthday: userData.birthday, // Birthday
      addressStreet1: userData.addressStreet1, // Address
      addressStreet2: userData.addressStreet2, // Address
      addressCity: userData.addressCity, // City
      addressState: userData.addressState, // State
      addressZip: userData.addressZip, // Zip Code
      personSlug: userData.personSlug, // Needed to determine if user already exists.
    };
  };

  addOrUpdateUser = async (userDataOriginal) => {
    try {
      var userData = this.selectOnlyProfileFields(userDataOriginal);
      userData.birthday = moment(
        userDataOriginal.birthdayReadable
          ? userDataOriginal.birthdayReadable
          : null,
        "MM-DD-YYYY",
      ).toDate();
      if (userData.id) {
        // TODO: Change to attempt to load the user to see if the user exists, rather than assuming it doesn't.
        // let user = loadUser(userData.id)
        // if (!user) {
        //   console.log("User not found in database.")
        //   res = await cleanAndSaveNewUser(userData)
        // }
        if (!userData.personSlug) {
          const addSlugToUserObj = await addNewUserIDAndSlug({
            id: userData.id,
            firstName: userData.firstName,
            lastName: userData.lastName,
          });
          const res = await updateUserInDb(addSlugToUserObj);
          return res;
        } else {
          const res = await updateUserInDb(userData);
          return res;
        }
      } else {
        return null;
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again later.");
    }
  };

  handleNextClick = () => {
    if (this.state.termsAccepted) this.clickSignUp(this.props.currentUser);
    else toast.info("Please accept terms and conditions.");
  };

  clickSignUp = (currentUser) => {
    if (!currentUser.firstName) {
      alert("First name is required.");
      return false;
    }
    if (!currentUser.lastName) {
      alert("Last name is required.");
      return false;
    }
    this.setState({ savingUser: true });

    // The updateMadeToUser in the Usercontext isn't working. Need to find a different way to do this.
    // if (!updateMadeToUser) {
    //   console.log("No updates made to user")
    //   if (this.props.currentUser.id === ''){
    //     alert("Please update your profile before continuing.")
    //     return
    //   } else {
    //     this.props.navigate('/budget')
    //     return
    //   }
    // }
    this.addOrUpdateUser(currentUser)
      .then((user) => {
        this.setState({ savingUser: false });
        if (!user) {
          toast.error("Error saving user. Please try again.");
        } else {
          console.log("User Saved");
          toast.success("Updated profile successfully!");
          this.props.navigate("/budget");
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ savingUser: false });
        alert("Error saving user. Please try again.");
      });
  };

  handleTermsChange = () => {
    this.setState({ termsAccepted: !this.state.termsAccepted });
  };

  render() {
    return (
      <Container>
        <H1 style={{ textAlign: "center", padding: 30 }}>Profile</H1>
        <Row>
          <Col sm="12" md={{ size: 6, offset: 3 }}>
            <H4>Tell us about yourself</H4>
            <p />
          </Col>
        </Row>
        <Row>
          <Col sm="12" md={{ size: 4, offset: 4 }}>
            <UserForm
              currentUser={this.props.currentUser}
              updateUser={this.props.updateUser}
              termsAccepted={this.props.termsAccepted}
              handleTermsChange={this.handleTermsChange}
            />
          </Col>
        </Row>
        <Row>
          <Col sm="12" md={{ size: 2, offset: 8 }}>
            {this.state.savingUser ? (
              <Spinner color="primary" />
            ) : (
              <Button onClick={this.handleNextClick} color="primary">
                Next
              </Button>
            )}
          </Col>
        </Row>
        <p />
      </Container>
    );
  }
}

export default withUserContext(withRouter(Profile));
