import { Fragment } from "react";

import Hero from "../components/Hero";
import FeaturedComponent from "../components/Featured";
import SoftwareAbout from "../components/softwareAbout";
import TestmonialComponent from "../components/Testmonial";
import PricingTable from "../components/PricingTable";
import FooterComponent from "../components/Footer";

const LandingPage = () => {
  return (
    <Fragment>
      <Hero id="home" />
      <SoftwareAbout id="about" />
      <FeaturedComponent className="featuredArea softwareFeaturedArea" />
      <TestmonialComponent className="testmonialArea" id="client" />
      <PricingTable id="pricing" noGutters className="softwarePricingTable" />
      <FooterComponent className="footerArea bookFooterArea" />
    </Fragment>
  );
};
export default LandingPage;
