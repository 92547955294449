import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Spinner,
} from "reactstrap";

import { withRouter } from "../legacy/react-router-old";
import { H3 } from "../components/elements";
import { UserContext } from "../UserContext";
import { updateBudgetOfUser } from "../services/user";

class SetBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      savingUser: false,
    };
  }

  // TODO: Modify to do error handling... How to handle the case when some of the attributes are missing?
  updateUser = async (currentUser) => {
    if (!currentUser) {
      return false;
    }
    const userDetail = {
      input: {
        annualBudget: currentUser.annualBudget,
        id: currentUser.id,
      },
    };
    try {
      const user = await updateBudgetOfUser(userDetail);
      return user;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  clickNext = (currentUser) => {
    this.setState({ savingUser: true });
    this.updateUser(currentUser)
      .then((user) => {
        this.setState({ savingUser: false });
        if (!user) {
          alert(
            "Error: Failed to save budget. Please try contact customer support.",
          );
          return;
        } else {
          this.props.navigate("/choose-whom"); //'/upcoming-birthdays')
        }
      })
      .catch((err) => console.log(err));
  };

  render() {
    return (
      <UserContext.Consumer>
        {({ currentUser, updateUser }) => (
          <Container>
            <H3 style={{ textAlign: "center", padding: 30 }}>
              How much do you want to spend on gifts each year?
            </H3>
            <Row>
              <Col sm="12" md={{ size: 2, offset: 5 }}>
                <Form onSubmit={this.onSubmit}>
                  <FormGroup>
                    <Label for="annualBudget">Budget</Label>
                    <Input
                      type="text"
                      name="annualBudget"
                      id="annualBudget1"
                      placeholder="$600"
                      value={currentUser.annualBudget}
                      onChange={(event) =>
                        updateUser("annualBudget", event.target.value)
                      }
                    />
                    per year
                  </FormGroup>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col sm="12" md={{ size: 2, offset: 8 }}>
                {this.state.savingUser ? (
                  <Spinner color="primary" />
                ) : (
                  <Button
                    onClick={() => this.clickNext(currentUser)}
                    color="primary"
                  >
                    Next
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withRouter(SetBudget);
