import React, { Fragment } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  Spinner,
  ModalFooter,
} from "reactstrap";
import moment from "moment";

//import { AMAZON_TAG } from "../constants";
import { UserContext } from "../UserContext";
import { H1, H3, H5 } from "../components/elements";
import {
  slugToName,
  slugToId,
  checkGiftPurchasedRecently,
  extractExternalIdFromUrl,
  extractExternalDomainFromUrl,
  addParamsToAmazonProductUrl,
  recordGiftPurchase,
} from "../utils";

import { addProduct, getAllProducts } from "../services/products";
import { cleanFriendList, loadCognitoUser, loadUser } from "../services/user";
import { loadResponses } from "../services/questionsAndResponses";
import {
  createGiftRecommendationForFriend,
  deleteGiftRecommendationForFriend,
  getGiftRecommendationsForFriend,
  listAllGiftRecommendations,
} from "../services/giftRecommendations";
import { getExternalProductdata } from "../services/externalProducts";

import { withRouter } from "../legacy/react-router-old";
import GiftItemCard from "../components/GiftItemCard";
import AddGiftRecommendation from "../components/AddGiftRecommendation";

//import "./style.css";
import { toast } from "react-toastify";

const sleep = (milliseconds) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

class GiftIdeas extends React.Component {
  static contextType = UserContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: 4,
      cardItems: [],
      currentPerson: null,
      cognitoUserDataKey: null,
      currentPersonUserID: null,
      personSlug: "",
      loadingGiftIdeas: false,
      friendList: [],
      currentFriend: {},
      numberOfResponses: 0,
      freeFormGiftIdeas: [],
      aiGiftIdeas: [],
      giftRecommendations: [],
      giftRecommendation: {
        id: "",
        userFriendID: "",
        giftRecommendationProductId: "",
        viewed: false,
        purchased: false,
        active: true,
        productExternalUrl: "",
      },
      products: [],
      savingProductByUrl: false,
      addProductByUrlModalVisible: false,
      isOpenDeleteModal: false,
      isDeletingProduct: false,
      deleteProductId: "",
    };
  }

  componentDidMount() {
    if (!this.props.match) {
      console.log("Error: Missing Params.");
      return;
    }
    // TODO: Refactor this to be in a shared base component for all the pages which extract the UserID from the params
    const { personSlug } = this.props.match.params;

    const name = slugToName(personSlug);
    const currentPersonUserID = slugToId(personSlug);
    this.setState({ currentPerson: name, personSlug, currentPersonUserID });

    // Pull this from the UserContext ?
    let user = this.context.user;
    //if (!user){ this.context.refreshUserInfo(); }
    //this.setState({ cognitoUserInfo: user, cognitoUserDataKey: user.id })

    this.loadUserFriendModel(user);
    this.getProducts();
  }

  getProducts = async () => {
    try {
      const res = await getAllProducts();
      if (res) {
        this.setState({
          products: res || [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  setGiftRecommendationUserInfo = () => {
    const giftRecommendation = {
      giftRecommendationGiftGiverId: this.state.cognitoUserDataKey,
      giftReceiverId: this.state.currentFriend.userId,
      giftRecommendationProductId: "",
      viewed: false,
      purchased: false,
      active: true,
    };
    this.setState({
      giftRecommendation,
      loading: this.state.loading - 1,
    });
  };

  loadUserFriendModel = async (currentUser) => {
    if (!currentUser) {
      let { cognitoUserInfo, cognitoUserDataKey } = await loadCognitoUser();
      this.setState({ cognitoUserInfo, cognitoUserDataKey });
      currentUser = await loadUser(cognitoUserDataKey);
      if (!currentUser) {
        console.log("Error: No User Found.");
        return;
      }
      //console.log(currentUser)
    }
    //currentUserDataKey, currentPersonUserID
    // TODO: Modify to use UserContext

    //let user = await loadUser(cognitoUserDataKey)
    // if (!user){
    //   return
    // }

    // TODO: Modify this to load the specific user based on the currentPersonUserId
    //let userFriendObject = getUserFriend(userFriendId)
    var userFriendList = cleanFriendList(currentUser);
    //console.log("UserFriendList: ", userFriendList)
    var currentUserFriend = userFriendList
      .map((userFriendObject) => {
        //console.log(userFriendObject.user.id)
        if (userFriendObject.user.id === this.state.currentPersonUserID) {
          //console.log(userFriendObject)
          return userFriendObject;
        }
        return undefined;
      })
      .filter((item) => item !== undefined)[0]; // Return only one item, because there should be only one matching friend.
    //console.log(currentUserFriend)

    if (!currentUserFriend) {
      console.log(
        "Friend information not found for...  ",
        this.state.currentPersonUserID,
      );
      this.setState({ loading: this.state.loading - 1 });
      return;
    }
    currentUserFriend.lastGiftPurchasedDateTime = new Date(
      currentUserFriend.lastGiftPurchasedDateTime,
    );
    currentUserFriend.user.birthdayReadable = currentUserFriend.user.birthday
      ? moment(currentUserFriend.user.birthday).format("MM/DD/YYYY")
      : null;

    const freeFormGiftList = currentUserFriend?.generalGiftIdeas?.map(
      (gift) => {
        return gift.description;
      },
    );
    this.setState({
      currentUserFriend: currentUserFriend,
      currentFriend: currentUserFriend.user,
      loading: this.state.loading - 1,
      freeFormGiftIdeas: freeFormGiftList
        ? [...this.state.freeFormGiftIdeas, ...freeFormGiftList]
        : [],
    });

    this.setGiftRecommendationUserInfo();
    this.listGiftRecommendations();
    this.loadFreeFormGiftIdeas();
  };

  loadFreeFormGiftIdeas = () => {
    let takenUserId =
      this.props.responsesByUserOrFriend === "friend"
        ? this.state.currentPersonUserID
        : this.state.cognitoUserDataKey;
    loadResponses(takenUserId, this.state.currentPersonUserID)
      .then((responses) => {
        const answers = responses.answers?.items;

        if (!answers) {
          console.log("WARNING:: No Answers found. ", responses);
          this.setState({
            freeFormGiftIdeas: [],
            aiGiftIdeas: [],
            loading: this.state.loading - 1,
          });
          return;
        }
        //console.log("----------------", answers);
        this.setState({ numberOfResponses: answers.length });
        const freeFormGiftIdeas = answers
          .map((answer) => {
            return answer.giftIdea;
          })
          .filter((giftIdea) => {
            return giftIdea;
          });
        const aiGiftIdeas = answers
          .map((answer) => {
            return answer.aiGiftIdea;
          })
          .filter((giftIdea) => {
            return giftIdea;
          });
        this.setState({
          aiGiftIdeas: [...this.state.aiGiftIdeas, ...aiGiftIdeas],
          freeFormGiftIdeas: [
            ...this.state.freeFormGiftIdeas,
            ...freeFormGiftIdeas,
          ],
          answers,
          loading: this.state.loading - 1,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: this.state.loading - 1 });
      });
  };

  listGiftRecommendations = () => {
    this.setState({ loadingGiftIdeas: true });
    const userFriendId = this.state.currentUserFriend.id;
    //console.log("UserFriendID:: ", userFriendId);
    getGiftRecommendationsForFriend(userFriendId)
      .then((giftRecommendations) => {
        //console.log("Gift Recommendations:: ", giftRecommendations);
        this.setState({
          giftRecommendations,
          cardItems: this.getCardItems(giftRecommendations),
          loadingGiftIdeas: false,
          loading: this.state.loading - 1,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getCardItems = (giftRecommendations) => {
    // console.log(this.state.giftRecommendations)

    // When it initially loads this will be empty.
    if (!giftRecommendations) return [];
    return giftRecommendations
      .map((giftIdea, index) => {
        const { product } = giftIdea;
        if (!product) {
          return null;
        }
        //console.log(index);
        const ellipsis = product.description?.length > 80 ? "..." : "";
        return {
          id: product.id + "__" + index,
          title: product.title,
          subtitle: product.externalId,
          imageUrl: product.productImageUrl,
          cardText: product.description
            ? product.description?.slice(0, 80) + ellipsis
            : "",
          price: product.price,
          cardLink: {
            text: "Buy Now!",
            url: addParamsToAmazonProductUrl(product.externalUrl),
          },
          rating: product.rating,
        };
      })
      .filter((item) => item !== null);
  };

  handleDeleteProductIconClick = (id) => {
    const productId = id.split("__")[0];
    //console.log("Product ID: ", productId)
    //console.log("Gift Recommendations: ", this.state.giftRecommendations)
    const findObj = this.state.giftRecommendations.find((gift) => {
      return gift.product?.id === productId;
    });
    //console.log("handleDeleteProductIconClick:: ", findObj);
    this.setState({
      isOpenDeleteModal: !this.state.isOpenDeleteModal,
      deleteProductId: findObj?.id,
    });
  };

  handleDeleteProduct = async () => {
    this.setState({ isDeletingProduct: true });
    try {
      if (this.state.deleteProductId) {
        const res = await deleteGiftRecommendationForFriend(
          this.state.deleteProductId,
        );
        if (res)
          toast.success("Deleted product as gift recommendation successfully!");
        await this.listGiftRecommendations();
      } else {
        console.log("deleteProductId was not found")
        toast.error("Something went wrong! Please try again later!");
      }
    } catch (err) {
      toast.error("Please try again to delete friend!");
      console.log(err);
    }
    this.setState({ isDeletingProduct: false, isOpenDeleteModal: false });
  };

  renderCardItems = (startItem, endItem) => {
    return this.state.cardItems.slice(startItem, endItem).map((cardItem) => {
      //console.log(cardItem.id);
      return (
        <GiftItemCard
          key={cardItem.id}
          id={cardItem.id}
          title={cardItem.title}
          subtitle={cardItem.subtitle}
          imageUrl={cardItem.imageUrl}
          cardText={cardItem.cardText}
          cardLink={cardItem.cardLink}
          price={cardItem.price}
          rating={cardItem.rating}
          handleDeleteProductIconClick={() =>
            this.handleDeleteProductIconClick(cardItem.id)
          }
        />
      );
    });
  };

  handleProductByUrlCloseModal = () => {
    this.setState({
      ...this.state,
      addProductByUrlModalVisible: !this.state.addProductByUrlModalVisible,
      savingProductByUrl: false,
      giftRecommendation: {
        ...this.state.giftRecommendation,
        giftRecommendationProductId: "",
        productExternalUrl: "",
      },
    });
  };

  createNewProduct = async (extractProductId) => {
    var newProductDetails;

    const extractDomain = await extractExternalDomainFromUrl(
      this.state.giftRecommendation.productExternalUrl,
    );
    newProductDetails = {
      externalId: extractProductId,
      externalUrl: this.state.giftRecommendation.productExternalUrl,
      externalDomain: extractDomain,
      title: "",
      description: "",
      productImageUrl: "",
      rating: null,
      price: null,
      currency: "",
    };

    try {
      const addProductResponse = await addProduct({ input: newProductDetails });
      const productId = addProductResponse.data.createProduct.id;
      console.log("Product Added:: ", addProductResponse.data.createProduct);
      // Modify to be an asynchronous Lambda Function call.
      getExternalProductdata(extractProductId, productId)
        .then((newProductDetails) => {
          console.log("newProductDetails:: ", newProductDetails);
          // Update product details here with new information.
          this.listGiftRecommendations();
        })
        .catch((err) => {
          console.log(err);
        });

      return productId || null;
    } catch (err) {
      console.log(err);
    }
  };

  addNewProductAsGiftRecommendation = async (newCreatedProductId) => {
    this.setState({
      ...this.state.giftRecommendation,
      giftRecommendationProductId:
        newCreatedProductId ||
        this.state.giftRecommendation.giftRecommendationProductId,
    });
    try {
      const response = await listAllGiftRecommendations(
        this.state.giftRecommendationProductId,
        this.state.currentUserFriend.id,
      );
      if (response) {
        const giftRecommendationInput = {
          giftRecommendationProductId:
            newCreatedProductId ||
            this.state.giftRecommendation.giftRecommendationProductId,
          userFriendID: this.state.currentUserFriend.id, // This is the ID of the UserFriend Connection not a userid.
          active: true,
        };
        const res = await createGiftRecommendationForFriend(
          giftRecommendationInput,
        );
        if (res)
          toast.success("Added product as gift recommendation successfully!");
        this.handleProductByUrlCloseModal();
      } else {
        toast.info("Product is already added!");
        this.handleProductByUrlCloseModal();
      }
    } catch (err) {
      console.log(err);
      this.handleProductByUrlCloseModal();
    }
    this.setState({ loading: this.state.loading - 1 });
    await this.listGiftRecommendations();
  };

  addProductByUrl = async () => {
    this.setState({ savingProductByUrl: true });
    if (!this.state.giftRecommendation.productExternalUrl) {
      await setTimeout(() => {}, 1000);
      await this.addNewProductAsGiftRecommendation();
      await this.getProducts();
    } else {
      const extractProductId = await extractExternalIdFromUrl(
        this.state.giftRecommendation.productExternalUrl,
      );
      let newCreatedProductId = null;
      const response = await this.state.products.find((product) => {
        return product.externalId === extractProductId;
      });
      //console.log("Existing Products found: ", response);
      if (!response) {
        newCreatedProductId = await this.createNewProduct(extractProductId);
      } else {
        console.log("Product Already found in list of Products.");
        //this.handleProductByUrlCloseModal();
      }
      await this.addNewProductAsGiftRecommendation(
        response ? response.id : newCreatedProductId,
      );
      await this.getProducts();
    }
  };

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  updateMarkGiftPurchased = async () => {
    const res = await recordGiftPurchase(this.state.currentUserFriend.id);
    if (res) {
      toast.success("Updated successfully!");
      await this.loadUserFriendModel(this.context.user);
    } else {
      toast.error("Please try again after sometime!");
    }
  };

  render() {
    if (this.state.loading > 0) {
      return <p />; //<h3> Loading... </h3>
      //return <LoadingSpinner/>
    } else if (
      this.state.cardItems.length === 0 &&
      this.state.freeFormGiftIdeas.length === 0
    ) {
      console.log("state ", this.state);
      if (this.state.numberOfResponses === 0) {
        // If there is no information about the user then answer questions about them. Otherwise enter gift ideas.
        toast.error(
          "No information found about the person, please return to answer more questions about them.",
        );
        sleep(2000);
        return <Navigate to={"/getting-to-know/" + this.state.personSlug} />;
      } else {
        return (
          <Fragment>
            <h1>No Gift Ideas found. </h1>
            <Link to={"/user-gift-idea/" + this.state.personSlug}>
              <Button>User Gift Ideas</Button>
            </Link>
          </Fragment>
        );
      }
    }
    return (
      <Container>
        <AddGiftRecommendation
          isOpen={this.state.addProductByUrlModalVisible}
          onClose={this.handleProductByUrlCloseModal}
          onSubmit={this.addProductByUrl}
          updateValue={this.updateValue}
          savingData={this.state.savingProductByUrl}
          productExternalUrl={this.state.giftRecommendation.productExternalUrl}
          products={this.state.products}
          giftRecommendation={this.state.giftRecommendation}
        />

        <Row>
          <Col sm="12" md={{ offset: 3, size: 6 }} className="mb-4">
            <div style={{ textAlign: "center", marginTop: 80 }}>
              <H3 data-cy="gift-ideas-title">Gift Ideas</H3>
              for
              <H1>
                {this.state.currentPerson ? this.state.currentPerson : ""}
              </H1>
              {this.state.currentFriend.birthdayReadable ? (
                <div>
                  <H5>Birthday</H5>
                  <H3>{this.state.currentFriend.birthdayReadable}</H3>
                </div>
              ) : (
                ""
              )}
            </div>
          </Col>
        </Row>

        <Row>
          {this.state.cardItems.length > 0 ? (
            this.renderCardItems()
          ) : (
            <Col md={12}>
              <div className="no-product-found-text">No Product Found!</div>
            </Col>
          )}
        </Row>

        <p />
        {this.state.aiGiftIdeas.length > 0 ? (
          <div>
            <h3>AI Generated Gift Ideas</h3>
            <Row>
              <Col
                sm="12"
                md={{ size: 8, offset: 2 }}
                className="d-flex justify-content-center"
              >
                {this.state.aiGiftIdeas.length > 0 ? (
                  // Load Responses and display ai generated gift ideas.
                  // TODO: Update tracking pixel
                  <table>
                    <tbody>
                      {this.state.aiGiftIdeas.map((giftIdea, index) => (
                        <tr key={index}>
                          <td style={{ padding: 20 }}>{giftIdea}</td>
                          <td>
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={addParamsToAmazonProductUrl(
                                "https://www.amazon.com/s?k=" + giftIdea,
                              )}
                            >
                              <Button color="primary">Find on Amazon</Button>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : this.state.cardItems.length === 0 ? (
                  <div>No AI generated gift ideas yet.</div>
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        <h3 className="mt-4">Your Gift Ideas</h3>
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            {this.state.freeFormGiftIdeas.length > 0 ? (
              // Load Responses and display gift ideas.
              // TODO: Update tracking pixel
              <table>
                <tbody>
                  {this.state.freeFormGiftIdeas.map((giftIdea, index) => (
                    <tr key={index}>
                      <td style={{ padding: 20 }}>{giftIdea}</td>
                      <td>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={addParamsToAmazonProductUrl(
                            "https://www.amazon.com/s?k=" + giftIdea,
                          )}
                        >
                          <Button color="primary">Find on Amazon</Button>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : this.state.cardItems.length === 0 ? (
              <div>No gift ideas yet. Add some below...</div>
            ) : (
              ""
            )}
            {/* //The tracking pixel from amazon doesn't seem to be valid any longer.
            <img
              src={`//ir-na.amazon-adsystem.com/e/ir?t=${AMAZON_TAG}&l=ur2&o=1`}
              width="1"
              height="1"
              border="0"
              alt=""
              style={{ border: "none", margin: 0 }}
            />*/}
          </Col>
        </Row>
        <p />
        <Row>
          <Col sm="12" md={{ size: 10, offset: 1 }}>
            Do you have a specific product in mind which you’d like to save for
            later?
            <p />
            <Button
              title="Once you've found a specific product on Amazon which you'd like to save for later, you can add it here."
              onClick={() =>
                this.setState({
                  addProductByUrlModalVisible:
                    !this.state.addProductByUrlModalVisible,
                })
              }
              data-cy="add-item-from-amazon-button"
            >
              Add Item from Amazon
            </Button>
          </Col>
        </Row>
        <p />
        <Row>
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            <Button color="secondary" onClick={() => this.props.navigate(-1)}>
              Back
            </Button>{" "}
            <Link
              to={
                this.props.responsesByUserOrFriend === "friend"
                  ? "/friend-survey-response/" + this.state.personSlug
                  : "/user-gift-idea/" + this.state.personSlug
              }
            >
              <Button color="secondary">Add More Gift Ideas</Button>
            </Link>{" "}
            <Link to="/upcoming-birthdays">
              <Button color="primary">Friends List</Button>
            </Link>{" "}
            {!checkGiftPurchasedRecently(
              this.state.currentUserFriend.lastGiftPurchasedDateTime,
            ) ? (
              <Button
                onClick={() => this.updateMarkGiftPurchased()}
                color="primary"
                data-cy="record-gift-purchased-button"
              >
                Mark Gift Purchased
              </Button>
            ) : (
              <div data-cy="status-gift-purchased">
                Last Gift purchased on{" "}
                {moment(
                  this.state.currentUserFriend.lastGiftPurchasedDateTime,
                ).format("MM/DD/YYYY")}
              </div>
            )}
          </Col>
        </Row>
        <p />
        <Modal
          isOpen={this.state.isOpenDeleteModal}
          toggle={() =>
            this.setState({ isOpenDeleteModal: !this.state.isOpenDeleteModal })
          }
          className="modal-delete"
        >
          <ModalHeader
            toggle={() =>
              this.setState({
                isOpenDeleteModal: !this.state.isOpenDeleteModal,
              })
            }
          >
            {`Are you sure you want to delete this product?`}
          </ModalHeader>
          <ModalFooter>
            <div
              style={{ display: "flex", justifyContent: "right", gap: "10px" }}
            >
              <Button
                color="secondary"
                onClick={() =>
                  this.setState({
                    isOpenDeleteModal: !this.state.isOpenDeleteModal,
                  })
                }
              >
                Cancel
              </Button>
              {this.state.isDeletingProduct ? (
                <Spinner />
              ) : (
                <Button
                  color="primary"
                  data-cy="delete-button"
                  onClick={this.handleDeleteProduct}
                >
                  Delete
                </Button>
              )}
            </div>
          </ModalFooter>
        </Modal>
      </Container>
    );
  }
}

export default withRouter(GiftIdeas);
