import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { H2 } from "../components/elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import {
  addBirthdayReadable,
  extractExternalDomainFromUrl,
  extractExternalIdFromUrl,
} from "../utils";
import { loadUser } from "../services/user";
import { createGiftRecommendationForFriend } from "../services/giftRecommendations";
import { addProduct, getAllProducts } from "../services/products";
import { withUserContext } from "../UserContext";
import AddGiftRecommendationFromAmazon from "../components/AddGiftRecommendationFromAmazon";

class CreateGiftRecommendation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      friends: [],
      loading: true,
      products: [],
      addProductByUrlModalVisible: false,
      productAmazonUrl: "",
      savingProductByUrl: false,
      userFriendId: "",
    };
  }

  componentDidMount() {
    this.getProducts();
    this.listFriends();
  }

  listFriends = async () => {
    const user = this.props.currentUser;
    this.setState({
      cognitoUserInfo: user,
      cognitoUserDataKey: user.id,
    });

    try {
      const userDetails = await loadUser(user.id);
      const friends = addBirthdayReadable(userDetails.friends.items) || [];
      this.setState({
        friends,
        loading: false,
      });
    } catch (err) {
      console.log(err);
    }
  };

  handleProductByUrlCloseModal = () => {
    this.setState({
      addProductByUrlModalVisible: !this.state.addProductByUrlModalVisible,
      savingProductByUrl: false,
      productAmazonUrl: "",
    });
  };

  createNewProduct = async (extractProductId) => {
    try {
      const extractDomain = await extractExternalDomainFromUrl(
        this.state.productAmazonUrl,
      );
      const newProductDetails = {
        input: {
          externalId: extractProductId,
          externalUrl: this.state.productAmazonUrl,
          externalDomain: extractDomain,
          title: "",
          description: "",
          productImageUrl: "",
          rating: null,
          price: null,
          currency: "",
        },
      };
      const addProductRepsonse = await addProduct(newProductDetails);
      return addProductRepsonse.data.createProduct.id || null;
    } catch (err) {
      console.log(err);
    }
  };

  addNewProductAsGiftRecommendation = async (newCreatedProductId) => {
    try {
      const giftRecommendationInput = {
        giftRecommendationProductId: newCreatedProductId,
        userFriendID: this.state.userFriendId,
        active: true,
      };
      const res = await createGiftRecommendationForFriend(
        giftRecommendationInput,
      );
      if (res) alert("Saved Successfully!");
      this.setState({
        savingProductByUrl: false,
        addProductByUrlModalVisible: false,
        productAmazonUrl: "",
      });
    } catch (err) {
      console.log(err);
      this.setState({
        savingProductByUrl: false,
        addProductByUrlModalVisible: false,
        productAmazonUrl: "",
      });
    }
  };

  addProductByUrl = async (id = "") => {
    this.setState({ savingProductByUrl: true });
    if (id) {
      await this.addNewProductAsGiftRecommendation(id);
      await this.getProducts();
    } else {
      const extractProductId = await extractExternalIdFromUrl(
        this.state.productAmazonUrl,
      );
      let newCreatedProductId = null;
      const response = await this.state.products.find((product) => {
        return product.externalId === extractProductId;
      });
      if (!response) {
        newCreatedProductId = await this.createNewProduct(extractProductId);
      }
      await this.addNewProductAsGiftRecommendation(
        response ? response.id : newCreatedProductId,
      );
      await this.getProducts();
    }
  };

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  getProducts = async () => {
    try {
      const res = await getAllProducts();
      if (res) {
        this.setState({
          products: res || [],
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  renderFriends = () => {
    if (this.state.loading) {
      return <div>Loading...</div>;
    }
    return (
      <div>
        {this.state.friends.map((friend, index) => {
          return (
            <div key={index}>
              <Row>
                <Col>{friend.user.firstName + " " + friend.user.lastName}</Col>
                <Col>{friend.birthdayReadable}</Col>
                <Col>
                  <Button
                    onClick={() =>
                      this.setState({
                        addProductByUrlModalVisible:
                          !this.state.addProductByUrlModalVisible,
                        userFriendId: friend.id,
                      })
                    }
                    style={{ backgroundColor: "white", borderWidth: 0 }}
                  >
                    <FontAwesomeIcon
                      icon={faPlusCircle}
                      size="lg"
                      color="#33d3ea"
                    />
                  </Button>
                </Col>
              </Row>
              <p />
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    return (
      <Container>
        <AddGiftRecommendationFromAmazon
          isOpen={this.state.addProductByUrlModalVisible}
          onClose={this.handleProductByUrlCloseModal}
          onSubmit={(id) => this.addProductByUrl(id)}
          handleChange={(e) =>
            this.setState({ productAmazonUrl: e.target.value })
          }
          savingData={this.state.savingProductByUrl}
          amazonUrl={this.state.productAmazonUrl}
          products={this.state.products}
        />
        <div style={{ marginTop: 30 }}>
          <Row>
            <H2>Gift Ideas</H2>
          </Row>
          <Row>
            <Col>{this.renderFriends()}</Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default withUserContext(CreateGiftRecommendation);
