import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, CardGroup, Spinner, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { withUserContext } from "../UserContext";
import { withRouter } from "../legacy/react-router-old";
import { H1 } from "../components/elements";
import FriendProfileCard from "../components/FriendProfileCard";
import { loadUser, cleanFriendList } from "../services/user";
import FriendNameModal from "../components/FriendNameModal";
import { extractUsername, recordGiftPurchase } from "../utils";
import { MAX_NUMBER_FRIENDS_FREE } from "../constants";
import { toast } from "react-toastify";

const UpcomingBirthdays = (props) => {
  const [friendList, setFriendList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [newFriend, setNewFriend] = useState({});
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [sendersName, setSendersName] = useState(null);
  const [sendersUsername, setSendersUsername] = useState(null);
  const [sendersUserID, setSendersUserID] = useState(null);
  const [isEditIconClick, setIsEditIconClick] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const userData = props.currentUser;
      const username = extractUsername(userData.id);

      setSubscriptionPlan(userData.subscriptionPlan || null);
      setSendersName(userData.firstName || null);
      setSendersUsername(username || null);
      setSendersUserID(userData.id);

      const updatedUserData = await loadUser(props.currentUser.id);

      if (updatedUserData) {
        setIsLoading(false);
        setFriendList(cleanFriendList(updatedUserData));
      }
    };

    fetchData();
  }, [props.currentUser.id]);

  const recordGiftPurchased = async (personSlug) => {
    const currentFriendUserData = props.currentUser.friends.items.find(
      (item) => item.user.personSlug === personSlug,
    );
    const res = recordGiftPurchase(currentFriendUserData.id);
    if (res) {
      toast.success("Updated successfully!");
      setIsLoading(true);
      const updatedUserData = await loadUser(props.currentUser.id);
      if (updatedUserData) {
        setIsLoading(false);
        setFriendList(cleanFriendList(updatedUserData));
      }
    } else {
      toast.error("Please try again after sometime!");
    }
  };

  const renderFriendCards = (friendListSub) => {
    if (!friendListSub) {
      return <div />;
    }

    return friendListSub.map((friendInfo) => (
      <FriendProfileCard
        key={friendInfo.user.personSlug}
        personSlug={friendInfo.user.personSlug}
        fullName={friendInfo.user.firstName + " " + friendInfo.user.lastName}
        email={friendInfo.user.email}
        birthday={friendInfo.user.birthday}
        profileImageUrl={friendInfo.profileImageUrl}
        giftType={friendInfo.giftType}
        sendGift={friendInfo.selected ? "Yes" : "No"}
        relation={friendInfo.relation}
        lastGiftPurchasedDateTime={friendInfo.lastGiftPurchasedDateTime}
        recordGiftPurchased={recordGiftPurchased}
        sendersName={sendersName}
        sendersUsername={sendersUsername}
        sendersUserID={sendersUserID}
        handleEditIconClick={() => handleEditIconClick(friendInfo.user.id)}
      />
    ));
  };

  const toggleModal = () => {
    setModalVisible(!modalVisible);
    setNewFriend({});
    setIsEditIconClick(false);
  };

  const handleEditIconClick = (id) => {
    const editFriend = friendList.find((friend) => friend.user.id === id);
    setNewFriend({
      id: id,
      userFriendId: editFriend.id,
      firstName: editFriend.user.firstName,
      lastName: editFriend.user.lastName,
      email: editFriend.user.email,
      birthday: editFriend.user.birthday,
      personSlug: editFriend.user.personSlug,
      relation: editFriend.relation,
    });
    setModalVisible(true);
    setIsEditIconClick(!isEditIconClick);
  };

  const updateValue = (key, value) => {
    setNewFriend((prevFriend) => ({
      ...prevFriend,
      [key]: value,
    }));
  };

  const renderCardList = () => {
    var rows = [];
    for (var i = 0; i <= friendList.length; i = i + 3) {
      var j = Math.min(i + 3, friendList.length);
      var row = (
        <CardGroup key={i}>
          {renderFriendCards(friendList.slice(i, j))}
        </CardGroup>
      );
      rows.push(row);
    }
    return rows;
  };

  const handleSubmit = async (keepModalVisible) => {
    setNewFriend({
      firstName: "",
      lastName: "",
      email: "",
      birthday: "",
      personSlug: "",
      relation: "",
    });
    if (
      friendList.length > MAX_NUMBER_FRIENDS_FREE &&
      subscriptionPlan !== "premium" &&
      subscriptionPlan !== "ultimate" &&
      subscriptionPlan !== null
    ) {
      setModalVisible(false);
    } else {
      setModalVisible(keepModalVisible);
    }
    if (keepModalVisible) setModalVisible(true);
    else setModalVisible(false);
    setIsEditIconClick(false);
    setIsLoading(true);
    const updatedFriendsLists = await loadUser(props.currentUser.id);
    setFriendList(updatedFriendsLists.friends.items);
    setIsLoading(false);
  };

  return (
    <Container>
      <FriendNameModal
        isOpen={modalVisible}
        onClose={toggleModal}
        onSubmit={(keepModalVisible) => {
          handleSubmit(keepModalVisible);
        }}
        newFriend={newFriend}
        updateValue={updateValue}
        isEdit={isEditIconClick}
        id={props.currentUser.id}
      />
      <H1 data-cy="title-text" style={{ textAlign: "center" }}>
        Upcoming Birthdays
      </H1>
      <div data-cy="subtitle-text" style={{ textAlign: "center", padding: 10 }}>
        Click on a name below to see gift ideas.
      </div>
      <Row>
        {isLoading ? (
          <Col sm="12" md={{ size: 1, offset: 6 }}>
            {" "}
            <Spinner />
          </Col>
        ) : (
          <Col sm="12" md={{ size: 8, offset: 2 }}>
            {friendList.length ? (
              renderCardList()
            ) : (
              <div>
                No friends found.
                <br />
                <Link to="/choose-whom">Add more friends</Link> now.
              </div>
            )}
          </Col>
        )}
      </Row>
      <Row>
        <Col sm="12" md={{ offset: 2, size: 4 }}>
          {friendList.length > MAX_NUMBER_FRIENDS_FREE &&
          subscriptionPlan !== "premium" &&
          subscriptionPlan !== "ultimate" ? (
            <div>
              <a href="https://app.generous-giver.com/pricing">Upgrade Now</a>{" "}
              to Add More Friends
            </div>
          ) : (
            <Button
              onClick={toggleModal}
              style={{ backgroundColor: "white", borderWidth: 0 }}
              data-cy="add-friends-button"
            >
              <FontAwesomeIcon icon={faPlusCircle} size="3x" color="#33d3ea" />
              <div style={{ color: "black" }}>Add more friends</div>
            </Button>
          )}
        </Col>
      </Row>
      <p />
    </Container>
  );
};

export default withUserContext(withRouter(UpcomingBirthdays));
