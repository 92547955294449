import { Routes, Route } from "react-router-dom";

import RequireAuth from "./containers/auth/RequireAuth";
import Layout from "./Layout";
import Home from "./containers/Home";
import About from "./containers/About";
import Pricing from "./containers/Pricing";
import Profile from "./containers/Profile";
import SignUp from "./containers/SignUp";
import ChooseWhom from "./containers/ChooseWhom";
import SetBudget from "./containers/SetBudget";
import GettingToKnow from "./containers/GettingToKnow";
import TellUsAboutYourself from "./containers/TellUsAboutYourself";
//import GettingToKnowFriend from "./containers/GettingToKnowFriend";
import WishlistRequest from "./containers/WishlistRequest";
import UpcomingBirthdays from "./containers/UpcomingBirthdays";
import GiftIdeas from "./containers/GiftIdeas";
import SignOutPage from "./containers/SignOutPage";
import NotFoundPage from "./containers/NotFoundPage";
import Products from "./containers/Products";
import AppConfig from "./containers/AppConfig";
import SiteMap from "./containers/SiteMap";
import CreateGiftRecommendation from "./containers/CreateGiftRecommendation";
import UserGiftIdeas from "./containers/UserGiftIdeas";
import ViewSurveyResponse from "./containers/ViewSurveyResponse";
import Login from "./containers/auth/login";
import Dashboard from "./containers/Dashboard";
import WalkThroughVideo from "./containers/WalkThroughVideo";
import FriendGiftIdeas from "./containers/FriendGiftIdeas";

import { tellUsAboutYourselfPageURL } from "./constants";
import WalkThrough from "./containers/Walkthrough";
import Settings from "./containers/Settings";
import CreateHBDMessage from "./containers/CreateHBDMessage";
import { CREATE_BIRTHDAY_MESSAGE_ON } from "./constants/featureFlags";

function AppRoutes({ isLoaded, ...props }) {
  isLoaded = true;
  return (
    <Routes>
      <Route path="/" element={<Layout isLoaded={isLoaded} />}>
        <Route index element={<Home isLoaded={isLoaded} {...props} />} />
        <Route
          path="/dashboard"
          element={
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          }
        />
        <Route
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route
          path="/budget"
          element={
            <RequireAuth>
              <SetBudget />
            </RequireAuth>
          }
        />
        <Route
          path="/choose-whom"
          element={
            <RequireAuth>
              <ChooseWhom />
            </RequireAuth>
          }
        />
        <Route
          path="/getting-to-know/:personSlug"
          element={
            <RequireAuth>
              <GettingToKnow />
            </RequireAuth>
          }
        />
        <Route
          path="/user-gift-idea/:personSlug"
          element={
            <RequireAuth>
              <UserGiftIdeas />
            </RequireAuth>
          }
        />
        <Route
          path="/friend-gift-ideas/:personSlug"
          element={
            <RequireAuth>
              <FriendGiftIdeas />
            </RequireAuth>
          }
        />
        <Route
          path="/friend-survey-response/:personSlug"
          element={
            <RequireAuth>
              <ViewSurveyResponse />
            </RequireAuth>
          }
        />
        <Route
          path="/send-wishlist-request/:personSlug"
          element={
            <RequireAuth>
              <WishlistRequest />
            </RequireAuth>
          }
        />
        <Route
          path="/gift-ideas/:personSlug"
          element={
            <RequireAuth>
              <GiftIdeas />
            </RequireAuth>
          }
        />
        <Route
          path="/upcoming-birthdays"
          element={
            <RequireAuth>
              <UpcomingBirthdays />
            </RequireAuth>
          }
        />
        <Route
          path="/add-gift-idea"
          element={
            <RequireAuth>
              <CreateGiftRecommendation />
            </RequireAuth>
          }
        />
        <Route
          path="/products"
          element={
            <RequireAuth>
              <Products />
            </RequireAuth>
          }
        />
        <Route
          path="/app-config"
          element={
            <RequireAuth>
              <AppConfig />
            </RequireAuth>
          }
        />
        <Route
          path="/settings"
          element={
            <RequireAuth>
              <Settings />
            </RequireAuth>
          }
        />
        {CREATE_BIRTHDAY_MESSAGE_ON ? (
          <Route
            path="/birthday-message/:personSlug"
            element={
              <RequireAuth>
                <CreateHBDMessage />
              </RequireAuth>
            }
          />
        ) : (
          ""
        )}

        <Route path="/getting-started" element={<WalkThroughVideo />} />
        <Route path="/walkthrough" element={<WalkThrough />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/about" element={<About />} />
        <Route path="/signout" element={<SignOutPage />} />
        <Route
          path={tellUsAboutYourselfPageURL + ":personSlug"}
          element={<TellUsAboutYourself />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}

export default AppRoutes;
