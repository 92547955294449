import React from "react";
import { Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";

import LandingPage from "./LandingPage";

const Home = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  //console.log("isAuthenticated= ", isAuthenticated)
  //console.log("route= ", route)
  if (route != "authenticated") {
    return <LandingPage />;
  } else {
    return <Navigate to="/dashboard" />;
  }
};
export default Home;
