import React from "react";
import { Spinner } from "reactstrap";
import "./style.css";

const CustomLoadingSpinner = () => {
  return (
    <div className="loading-overlay">
      <Spinner color="primary" style={{ width: "3rem", height: "3rem" }} />
    </div>
  );
};

export default CustomLoadingSpinner;
