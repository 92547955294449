// components/Layout.js
import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useAuthenticator, Button } from "@aws-amplify/ui-react";

import HeaderComponent from "./components/Header";
import logo from "./images/GenerousGiverLogo4-white_small.png";

export default function Layout({ isLoaded }) {
  const { route, signOut } = useAuthenticator((context) => [
    context.route,
    context.signOut,
  ]);
  const navigate = useNavigate();

  function logOut() {
    signOut();
    console.log("Signing out...");
    navigate("/login");
  }

  return (
    <>
      <HeaderComponent
        logo={logo}
        className="softwareHeader"
        isLoaded={isLoaded}
        isAuthenticated={route === "authenticated"}
        signOut={() => logOut()}
      />

      <Outlet />
    </>
  );
}
