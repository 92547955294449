import React, { Fragment } from "react";
import { Container, Button } from "reactstrap";
import { Link, Navigate } from "react-router-dom";

import { withRouter } from "../legacy/react-router-old";
import EmailSignUp from "../components/EmailSignUp";
import { H3, H5, H6 } from "../components/elements";

const DISPLAY_FACEBOOK_LINK = false;

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.toggleModal = this.toggleModal.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.updateValue = this.updateValue.bind(this);
  }

  state = {
    modalVisible: false,
    toNextPage: false,
    email: "",
    password: null,
  };

  toggleModal = () => {
    this.setState({ modalVisible: false });
  };

  onSubmit = (e) => {
    this.toggleModal();
    this.props.navigate("/profile");
    //this.setState({toNextPage: true})
  };

  updateValue = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    if (this.state.toNextPage === true) {
      return <Navigate to="/profile" />;
    }

    return (
      <Fragment>
        {/* Need to outline and modify this workflow in order to use custom user creation workflow
           <EmailSignUp
            isOpen={this.state.modalVisible}
            toggleClose={this.toggleModal}
            onSubmit={this.onSubmit}
            email={this.state.email}
            updateValue={this.updateValue}
            /> */}
        <Container
          style={{ marginTop: 90, textAlign: "center", alignItems: "center" }}
        >
          <H3>Let's get started</H3>
          <H6>
            By continuing, you accept our{" "}
            <a
              href="https://www.generous-giver.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy and Policy
            </a>{" "}
            and{" "}
            <a
              href="https://www.generous-giver.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
            .
          </H6>
          <H5>Remember, it is better to give than receive.</H5>
          {DISPLAY_FACEBOOK_LINK ? (
            <div>
              <Button
                style={{
                  padding: 20,
                  marginTop: 20,
                  borderWidth: 0,
                  color: "white",
                  backgroundColor: "#4569B2",
                }}
              >
                CONTINUE WITH FACEBOOK
              </Button>
              <H5 style={{ margin: 20 }}>OR</H5>
            </div>
          ) : (
            <div />
          )}
          <Button
            style={{
              padding: 20,
              borderWidth: 0,
              color: "white",
              backgroundColor: "#33d3ea",
            }}
            onClick={() => {
              this.props.navigate("/profile");
            }}
          >
            SIGN UP NOW
          </Button>

          <div style={{ flexDirection: "column", marginTop: 20 }}>
            Already a client? <Link to="/login">Sign In</Link>
          </div>
          <div
            style={{
              marginTop: 20,
            }}
          >
            <a
              href="https://www.generous-giver.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy and Policy
            </a>
          </div>
          <div>
            <a
              href="https://www.generous-giver.com/terms-of-service"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of Service
            </a>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default withRouter(SignUp);
